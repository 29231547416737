import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccAccountMenu, LazyAccCircularAccountMenu, LazyAccCircularActivity, LazyAccCircularMenuLanguage, LazyAccCollapseNavigationFooter, LazyAccCollapseNavigationHeader, LazyAccPanelActivity, LazyAccPanelLanguage, LazyAccPanelSearch, LazyAccPanelTask, LazyAccSubsidebarAccount, LazyAccSubsidebarBank, LazyAccSubsidebarContact, LazyAccSubsidebarDashboards, LazyAccSubsidebarExpense, LazyAccSubsidebarItem, LazyAccSubsidebarJournal, LazyAccSubsidebarLayouts, LazyAccSubsidebarPayment, LazyAccSubsidebarPurchase, LazyAccSubsidebarSale, LazyAccSubsidebarTax, LazyAccThemeToggle, LazyAccToolbarAccountMenu, LazyAccToolbarActivity, LazyAccToolbarCustomize, LazyAccToolbarLanguage, LazyAccToolbarNotifications, LazyAccToolbarSearch, LazyAccTopnavWorkspaceDropdown, LazyTairoLogo, LazyTairoLogoText, LazyTairoToaster, LazyTairoTocAnchor, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["AccAccountMenu", LazyAccAccountMenu],
["AccCircularAccountMenu", LazyAccCircularAccountMenu],
["AccCircularActivity", LazyAccCircularActivity],
["AccCircularMenuLanguage", LazyAccCircularMenuLanguage],
["AccCollapseNavigationFooter", LazyAccCollapseNavigationFooter],
["AccCollapseNavigationHeader", LazyAccCollapseNavigationHeader],
["AccPanelActivity", LazyAccPanelActivity],
["AccPanelLanguage", LazyAccPanelLanguage],
["AccPanelSearch", LazyAccPanelSearch],
["AccPanelTask", LazyAccPanelTask],
["AccSubsidebarAccount", LazyAccSubsidebarAccount],
["AccSubsidebarBank", LazyAccSubsidebarBank],
["AccSubsidebarContact", LazyAccSubsidebarContact],
["AccSubsidebarDashboards", LazyAccSubsidebarDashboards],
["AccSubsidebarExpense", LazyAccSubsidebarExpense],
["AccSubsidebarItem", LazyAccSubsidebarItem],
["AccSubsidebarJournal", LazyAccSubsidebarJournal],
["AccSubsidebarLayouts", LazyAccSubsidebarLayouts],
["AccSubsidebarPayment", LazyAccSubsidebarPayment],
["AccSubsidebarPurchase", LazyAccSubsidebarPurchase],
["AccSubsidebarSale", LazyAccSubsidebarSale],
["AccSubsidebarTax", LazyAccSubsidebarTax],
["AccThemeToggle", LazyAccThemeToggle],
["AccToolbarAccountMenu", LazyAccToolbarAccountMenu],
["AccToolbarActivity", LazyAccToolbarActivity],
["AccToolbarCustomize", LazyAccToolbarCustomize],
["AccToolbarLanguage", LazyAccToolbarLanguage],
["AccToolbarNotifications", LazyAccToolbarNotifications],
["AccToolbarSearch", LazyAccToolbarSearch],
["AccTopnavWorkspaceDropdown", LazyAccTopnavWorkspaceDropdown],
["TairoLogo", LazyTairoLogo],
["TairoLogoText", LazyTairoLogoText],
["TairoToaster", LazyTairoToaster],
["TairoTocAnchor", LazyTairoTocAnchor],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
