
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "39e00530-d353-44cf-8640-21bc0f45adc5"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/vercel/path0/.app/app.config.ts"
import cfg1 from "/vercel/path0/layers/tairo/app.config.ts"
import cfg2 from "/vercel/path0/node_modules/.pnpm/@shuriken-ui+nuxt@1.1.3_nuxt@3.9.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node_jwqkculukuowxtohmgmrf5u3mi/node_modules/@shuriken-ui/nuxt/app.config.ts"
import cfg3 from "/vercel/path0/layers/tairo-layout-sidebar/app.config.ts"
import cfg4 from "/vercel/path0/layers/tairo-layout-collapse/app.config.ts"
import cfg5 from "/vercel/path0/layers/tairo-layout-topnav/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, cfg2, cfg3, cfg4, cfg5, inlineConfig)
