import { default as editTkREmjReaUMeta } from "/vercel/path0/.app/pages/account/account-type-locking/[id]/edit.vue?macro=true";
import { default as _91id_93XVWKvZYnt5Meta } from "/vercel/path0/.app/pages/account/account-type-locking/[id].vue?macro=true";
import { default as addyOvo4q5UbVMeta } from "/vercel/path0/.app/pages/account/account-type-locking/add.vue?macro=true";
import { default as importh6pIYCI6JoMeta } from "/vercel/path0/.app/pages/account/account-type-locking/import.vue?macro=true";
import { default as indexDv7VqDA2zfMeta } from "/vercel/path0/.app/pages/account/account-type-locking/index.vue?macro=true";
import { default as edit4aljphxYGiMeta } from "/vercel/path0/.app/pages/account/account-type/[id]/edit.vue?macro=true";
import { default as _91id_93zmRrVJjl1zMeta } from "/vercel/path0/.app/pages/account/account-type/[id].vue?macro=true";
import { default as addSFf159StMkMeta } from "/vercel/path0/.app/pages/account/account-type/add.vue?macro=true";
import { default as importVONo2fyeDtMeta } from "/vercel/path0/.app/pages/account/account-type/import.vue?macro=true";
import { default as index7GCSTpC9ZfMeta } from "/vercel/path0/.app/pages/account/account-type/index.vue?macro=true";
import { default as edithD3HFuSnpjMeta } from "/vercel/path0/.app/pages/account/account/[id]/edit.vue?macro=true";
import { default as viewBwUHarRgy1Meta } from "/vercel/path0/.app/pages/account/account/[id]/view.vue?macro=true";
import { default as _91id_93pUqIZmZ9MXMeta } from "/vercel/path0/.app/pages/account/account/[id].vue?macro=true";
import { default as adde52jd8132VMeta } from "/vercel/path0/.app/pages/account/account/add.vue?macro=true";
import { default as import2KXn16g5NOMeta } from "/vercel/path0/.app/pages/account/account/import.vue?macro=true";
import { default as index9XIpfAyCwBMeta } from "/vercel/path0/.app/pages/account/account/index.vue?macro=true";
import { default as editj9DayzEm85Meta } from "/vercel/path0/.app/pages/account/bank-account/[id]/edit.vue?macro=true";
import { default as _91id_93eZhMXlHOMZMeta } from "/vercel/path0/.app/pages/account/bank-account/[id].vue?macro=true";
import { default as addS7ES3wtZa9Meta } from "/vercel/path0/.app/pages/account/bank-account/add.vue?macro=true";
import { default as importDERahiBjdtMeta } from "/vercel/path0/.app/pages/account/bank-account/import.vue?macro=true";
import { default as indextrWy2NLrvQMeta } from "/vercel/path0/.app/pages/account/bank-account/index.vue?macro=true";
import { default as editOxDgDfkoLDMeta } from "/vercel/path0/.app/pages/account/budget-category/[id]/edit.vue?macro=true";
import { default as _91id_93gwhR4IiRZ9Meta } from "/vercel/path0/.app/pages/account/budget-category/[id].vue?macro=true";
import { default as addN65L7s0orhMeta } from "/vercel/path0/.app/pages/account/budget-category/add.vue?macro=true";
import { default as importj4MX6Rz7aZMeta } from "/vercel/path0/.app/pages/account/budget-category/import.vue?macro=true";
import { default as indexJDnjQmEC8rMeta } from "/vercel/path0/.app/pages/account/budget-category/index.vue?macro=true";
import { default as edit25e8w0HClVMeta } from "/vercel/path0/.app/pages/account/budget/[id]/edit.vue?macro=true";
import { default as _91id_93W2uKn7tv0RMeta } from "/vercel/path0/.app/pages/account/budget/[id].vue?macro=true";
import { default as addhO1SDlqv7fMeta } from "/vercel/path0/.app/pages/account/budget/add.vue?macro=true";
import { default as importt9GgEKNcYYMeta } from "/vercel/path0/.app/pages/account/budget/import.vue?macro=true";
import { default as indexXbxPwdvlGMMeta } from "/vercel/path0/.app/pages/account/budget/index.vue?macro=true";
import { default as editjTbWqVyZuKMeta } from "/vercel/path0/.app/pages/account/currency-adjustment/[id]/edit.vue?macro=true";
import { default as _91id_93bbnDYsNCEMMeta } from "/vercel/path0/.app/pages/account/currency-adjustment/[id].vue?macro=true";
import { default as addxRzUmrwSO3Meta } from "/vercel/path0/.app/pages/account/currency-adjustment/add.vue?macro=true";
import { default as importn6c01juexLMeta } from "/vercel/path0/.app/pages/account/currency-adjustment/import.vue?macro=true";
import { default as indexx5Dq98EdeCMeta } from "/vercel/path0/.app/pages/account/currency-adjustment/index.vue?macro=true";
import { default as edit2TBrNg517rMeta } from "/vercel/path0/.app/pages/account/current-asset/[id]/edit.vue?macro=true";
import { default as _91id_93pgbBStsllcMeta } from "/vercel/path0/.app/pages/account/current-asset/[id].vue?macro=true";
import { default as addsuABl5pGP0Meta } from "/vercel/path0/.app/pages/account/current-asset/add.vue?macro=true";
import { default as importFvrE0KncFZMeta } from "/vercel/path0/.app/pages/account/current-asset/import.vue?macro=true";
import { default as indexUtUwt4qyTHMeta } from "/vercel/path0/.app/pages/account/current-asset/index.vue?macro=true";
import { default as editrsftrifo8FMeta } from "/vercel/path0/.app/pages/account/exchange-rate/[id]/edit.vue?macro=true";
import { default as _91id_930tigiEX4AgMeta } from "/vercel/path0/.app/pages/account/exchange-rate/[id].vue?macro=true";
import { default as addrogm1cIi9ZMeta } from "/vercel/path0/.app/pages/account/exchange-rate/add.vue?macro=true";
import { default as importRdxBRPxtqnMeta } from "/vercel/path0/.app/pages/account/exchange-rate/import.vue?macro=true";
import { default as indexJ0SMekblRmMeta } from "/vercel/path0/.app/pages/account/exchange-rate/index.vue?macro=true";
import { default as editQZOS5GOEkAMeta } from "/vercel/path0/.app/pages/account/fixed-asset-disposal/[id]/edit.vue?macro=true";
import { default as _91id_93efZCVHKC2hMeta } from "/vercel/path0/.app/pages/account/fixed-asset-disposal/[id].vue?macro=true";
import { default as addXjJ29eKzGqMeta } from "/vercel/path0/.app/pages/account/fixed-asset-disposal/add.vue?macro=true";
import { default as importuNbAFvfOYYMeta } from "/vercel/path0/.app/pages/account/fixed-asset-disposal/import.vue?macro=true";
import { default as indexUZeE6AY4kBMeta } from "/vercel/path0/.app/pages/account/fixed-asset-disposal/index.vue?macro=true";
import { default as editIJLYKJC11NMeta } from "/vercel/path0/.app/pages/account/fixed-asset-nbv/[id]/edit.vue?macro=true";
import { default as _91id_93HnziyJAVzYMeta } from "/vercel/path0/.app/pages/account/fixed-asset-nbv/[id].vue?macro=true";
import { default as add1jXIpMHoIgMeta } from "/vercel/path0/.app/pages/account/fixed-asset-nbv/add.vue?macro=true";
import { default as importSu59JToZpnMeta } from "/vercel/path0/.app/pages/account/fixed-asset-nbv/import.vue?macro=true";
import { default as indexL0RTPVM2LiMeta } from "/vercel/path0/.app/pages/account/fixed-asset-nbv/index.vue?macro=true";
import { default as edito3R6vBtqHIMeta } from "/vercel/path0/.app/pages/account/fixed-asset/[id]/edit.vue?macro=true";
import { default as _91id_93nnzz0pZTP2Meta } from "/vercel/path0/.app/pages/account/fixed-asset/[id].vue?macro=true";
import { default as addKvq6bbTfvjMeta } from "/vercel/path0/.app/pages/account/fixed-asset/add.vue?macro=true";
import { default as importxCv1Qy0eoXMeta } from "/vercel/path0/.app/pages/account/fixed-asset/import.vue?macro=true";
import { default as indexCm7PDDvYqCMeta } from "/vercel/path0/.app/pages/account/fixed-asset/index.vue?macro=true";
import { default as accounting_45systemMRAajerrdgMeta } from "/vercel/path0/.app/pages/accounting-system.vue?macro=true";
import { default as forgot_45passwordjZ3b3vrR5GMeta } from "/vercel/path0/.app/pages/auth/forgot-password.vue?macro=true";
import { default as gen_452faBdAXBQgrzkMeta } from "/vercel/path0/.app/pages/auth/gen-2fa.vue?macro=true";
import { default as gen_45recovery_452fail2sc97e3pMeta } from "/vercel/path0/.app/pages/auth/gen-recovery-2fa.vue?macro=true";
import { default as indexPkY6dMCrINMeta } from "/vercel/path0/.app/pages/auth/profile/index.vue?macro=true";
import { default as settingsGG1svV8sUzMeta } from "/vercel/path0/.app/pages/auth/profile/settings.vue?macro=true";
import { default as recovery_452fa32nhAskNw6Meta } from "/vercel/path0/.app/pages/auth/recovery-2fa.vue?macro=true";
import { default as signinUKjnmwAHe9Meta } from "/vercel/path0/.app/pages/auth/signin.vue?macro=true";
import { default as signup71CxJQKKCmMeta } from "/vercel/path0/.app/pages/auth/signup.vue?macro=true";
import { default as verify_452faWHAbR753qhMeta } from "/vercel/path0/.app/pages/auth/verify-2fa.vue?macro=true";
import { default as verify_45emailtU7V9OEQhCMeta } from "/vercel/path0/.app/pages/auth/verify-email.vue?macro=true";
import { default as verify_45recovery_452fa5MGQAYzNb4Meta } from "/vercel/path0/.app/pages/auth/verify-recovery-2fa.vue?macro=true";
import { default as editoL60En2bOBMeta } from "/vercel/path0/.app/pages/bank/bank-adjustment/[id]/edit.vue?macro=true";
import { default as _91id_938yCdUFAcW1Meta } from "/vercel/path0/.app/pages/bank/bank-adjustment/[id].vue?macro=true";
import { default as addkAl70iEydKMeta } from "/vercel/path0/.app/pages/bank/bank-adjustment/add.vue?macro=true";
import { default as importoSsMfN1LMYMeta } from "/vercel/path0/.app/pages/bank/bank-adjustment/import.vue?macro=true";
import { default as indexlWyGanzRvEMeta } from "/vercel/path0/.app/pages/bank/bank-adjustment/index.vue?macro=true";
import { default as editP1fkp6UExOMeta } from "/vercel/path0/.app/pages/bank/bank-transaction/[id]/edit.vue?macro=true";
import { default as _91id_93M3QtmwluqJMeta } from "/vercel/path0/.app/pages/bank/bank-transaction/[id].vue?macro=true";
import { default as addtJyYaGdkYUMeta } from "/vercel/path0/.app/pages/bank/bank-transaction/add.vue?macro=true";
import { default as importxdeYllVWaFMeta } from "/vercel/path0/.app/pages/bank/bank-transaction/import.vue?macro=true";
import { default as indexpL48k85UdIMeta } from "/vercel/path0/.app/pages/bank/bank-transaction/index.vue?macro=true";
import { default as editAYCrF7RkkfMeta } from "/vercel/path0/.app/pages/bank/bank-transfer/[id]/edit.vue?macro=true";
import { default as _91id_9342FnUD0ychMeta } from "/vercel/path0/.app/pages/bank/bank-transfer/[id].vue?macro=true";
import { default as addRUeadrAMFjMeta } from "/vercel/path0/.app/pages/bank/bank-transfer/add.vue?macro=true";
import { default as import1AFTC4JXRHMeta } from "/vercel/path0/.app/pages/bank/bank-transfer/import.vue?macro=true";
import { default as indexhA7JA3IkydMeta } from "/vercel/path0/.app/pages/bank/bank-transfer/index.vue?macro=true";
import { default as dashboardshG8WIOwltMeta } from "/vercel/path0/.app/pages/business-management/dashboard.vue?macro=true";
import { default as checkGph7oBY2f1Meta } from "/vercel/path0/.app/pages/check/check.vue?macro=true";
import { default as editQd8kO8DyhEMeta } from "/vercel/path0/.app/pages/contact/contact/[id]/edit.vue?macro=true";
import { default as _91id_938wmAEqs1IgMeta } from "/vercel/path0/.app/pages/contact/contact/[id].vue?macro=true";
import { default as add1mgQC1hoUpMeta } from "/vercel/path0/.app/pages/contact/contact/add.vue?macro=true";
import { default as importhCSNkiUzPUMeta } from "/vercel/path0/.app/pages/contact/contact/import.vue?macro=true";
import { default as indexOZ4ijZKXdxMeta } from "/vercel/path0/.app/pages/contact/contact/index.vue?macro=true";
import { default as dashboardjs99NhV1xpMeta } from "/vercel/path0/.app/pages/dashboard.vue?macro=true";
import { default as ecommerce4NAhizbzOWMeta } from "/vercel/path0/.app/pages/ecommerce.vue?macro=true";
import { default as editvhlIr7YC63Meta } from "/vercel/path0/.app/pages/expense/expense-claim/[id]/edit.vue?macro=true";
import { default as _91id_93b3WXtnvQNAMeta } from "/vercel/path0/.app/pages/expense/expense-claim/[id].vue?macro=true";
import { default as addj8a7BU2Ie4Meta } from "/vercel/path0/.app/pages/expense/expense-claim/add.vue?macro=true";
import { default as import6Ev6AjkGfpMeta } from "/vercel/path0/.app/pages/expense/expense-claim/import.vue?macro=true";
import { default as index6Y5kcwWiKyMeta } from "/vercel/path0/.app/pages/expense/expense-claim/index.vue?macro=true";
import { default as edit93ulmsO6EbMeta } from "/vercel/path0/.app/pages/expense/expense/[id]/edit.vue?macro=true";
import { default as _91id_939FRaQnOKWCMeta } from "/vercel/path0/.app/pages/expense/expense/[id].vue?macro=true";
import { default as addDhfBuB7NzUMeta } from "/vercel/path0/.app/pages/expense/expense/add.vue?macro=true";
import { default as importkz6bBManC4Meta } from "/vercel/path0/.app/pages/expense/expense/import.vue?macro=true";
import { default as indexBe8hqZJvdqMeta } from "/vercel/path0/.app/pages/expense/expense/index.vue?macro=true";
import { default as editwfPoz5mS93Meta } from "/vercel/path0/.app/pages/expense/recurring-expense/[id]/edit.vue?macro=true";
import { default as _91id_93v9SjfMZ9zDMeta } from "/vercel/path0/.app/pages/expense/recurring-expense/[id].vue?macro=true";
import { default as adddtmbhr42KFMeta } from "/vercel/path0/.app/pages/expense/recurring-expense/add.vue?macro=true";
import { default as importjMIQ0afDKcMeta } from "/vercel/path0/.app/pages/expense/recurring-expense/import.vue?macro=true";
import { default as indexuEW1AKlvvZMeta } from "/vercel/path0/.app/pages/expense/recurring-expense/index.vue?macro=true";
import { default as financial_45modelfLfIXIJ1VEMeta } from "/vercel/path0/.app/pages/financial-model.vue?macro=true";
import { default as indexJX2dp37VJQMeta } from "/vercel/path0/.app/pages/index.vue?macro=true";
import { default as edit5HHSqTPvrPMeta } from "/vercel/path0/.app/pages/item/inventory-adjustment/[id]/edit.vue?macro=true";
import { default as _91id_93tlSs0aTUbWMeta } from "/vercel/path0/.app/pages/item/inventory-adjustment/[id].vue?macro=true";
import { default as addI1yK510vmXMeta } from "/vercel/path0/.app/pages/item/inventory-adjustment/add.vue?macro=true";
import { default as import9Mu3P6ZjNkMeta } from "/vercel/path0/.app/pages/item/inventory-adjustment/import.vue?macro=true";
import { default as indexkvNd5p2iCYMeta } from "/vercel/path0/.app/pages/item/inventory-adjustment/index.vue?macro=true";
import { default as editDExkjKB2z3Meta } from "/vercel/path0/.app/pages/item/item/[id]/edit.vue?macro=true";
import { default as _91id_93atXMvYV3AYMeta } from "/vercel/path0/.app/pages/item/item/[id].vue?macro=true";
import { default as addKJyoTdfXByMeta } from "/vercel/path0/.app/pages/item/item/add.vue?macro=true";
import { default as importhy9JFZknYUMeta } from "/vercel/path0/.app/pages/item/item/import.vue?macro=true";
import { default as indexIsME4Nk4zjMeta } from "/vercel/path0/.app/pages/item/item/index.vue?macro=true";
import { default as editWJ7DudvVdyMeta } from "/vercel/path0/.app/pages/item/price-list/[id]/edit.vue?macro=true";
import { default as _91id_93dE7BY4sefyMeta } from "/vercel/path0/.app/pages/item/price-list/[id].vue?macro=true";
import { default as addWOP1mJu9qfMeta } from "/vercel/path0/.app/pages/item/price-list/add.vue?macro=true";
import { default as importpKnfzieQnlMeta } from "/vercel/path0/.app/pages/item/price-list/import.vue?macro=true";
import { default as indexaOixNp1zAOMeta } from "/vercel/path0/.app/pages/item/price-list/index.vue?macro=true";
import { default as edit7UJtNCPqKnMeta } from "/vercel/path0/.app/pages/journal/journal/[id]/edit.vue?macro=true";
import { default as _91id_93FlvSVYCHk4Meta } from "/vercel/path0/.app/pages/journal/journal/[id].vue?macro=true";
import { default as addDw4WCrqpsvMeta } from "/vercel/path0/.app/pages/journal/journal/add.vue?macro=true";
import { default as importYzveCQH0WiMeta } from "/vercel/path0/.app/pages/journal/journal/import.vue?macro=true";
import { default as indexHyP1Uj12jOMeta } from "/vercel/path0/.app/pages/journal/journal/index.vue?macro=true";
import { default as edit5GHaDzr7I7Meta } from "/vercel/path0/.app/pages/journal/recurring-journal/[id]/edit.vue?macro=true";
import { default as _91id_93zVEXVyv8OfMeta } from "/vercel/path0/.app/pages/journal/recurring-journal/[id].vue?macro=true";
import { default as addkwOvnAQk4bMeta } from "/vercel/path0/.app/pages/journal/recurring-journal/add.vue?macro=true";
import { default as importOYAQTW7aJdMeta } from "/vercel/path0/.app/pages/journal/recurring-journal/import.vue?macro=true";
import { default as indexVem4ywWA2rMeta } from "/vercel/path0/.app/pages/journal/recurring-journal/index.vue?macro=true";
import { default as dashboardaGE68aD5Q9Meta } from "/vercel/path0/.app/pages/management/dashboard.vue?macro=true";
import { default as marketplaceaMMBvkinluMeta } from "/vercel/path0/.app/pages/marketplace.vue?macro=true";
import { default as editx154LuipCXMeta } from "/vercel/path0/.app/pages/payment/business-payment/[id]/edit.vue?macro=true";
import { default as _91id_93me50cOnMaVMeta } from "/vercel/path0/.app/pages/payment/business-payment/[id].vue?macro=true";
import { default as addFq1tdvU5WmMeta } from "/vercel/path0/.app/pages/payment/business-payment/add.vue?macro=true";
import { default as importDJffI50cLqMeta } from "/vercel/path0/.app/pages/payment/business-payment/import.vue?macro=true";
import { default as index8rcLVMEJQvMeta } from "/vercel/path0/.app/pages/payment/business-payment/index.vue?macro=true";
import { default as editHB2Jh6xOnMMeta } from "/vercel/path0/.app/pages/payment/customer-payment/[id]/edit.vue?macro=true";
import { default as _91id_934HJgAR8tiEMeta } from "/vercel/path0/.app/pages/payment/customer-payment/[id].vue?macro=true";
import { default as addJJzwiMufr1Meta } from "/vercel/path0/.app/pages/payment/customer-payment/add.vue?macro=true";
import { default as import5RNoNW7CF9Meta } from "/vercel/path0/.app/pages/payment/customer-payment/import.vue?macro=true";
import { default as indexk80MFuns6fMeta } from "/vercel/path0/.app/pages/payment/customer-payment/index.vue?macro=true";
import { default as edit3OoFtDlB5DMeta } from "/vercel/path0/.app/pages/payment/over-payment/[id]/edit.vue?macro=true";
import { default as _91id_93tqeiAR4vrjMeta } from "/vercel/path0/.app/pages/payment/over-payment/[id].vue?macro=true";
import { default as addgfujAoITyPMeta } from "/vercel/path0/.app/pages/payment/over-payment/add.vue?macro=true";
import { default as importbKPJ89lrdOMeta } from "/vercel/path0/.app/pages/payment/over-payment/import.vue?macro=true";
import { default as indexx7lS45lET6Meta } from "/vercel/path0/.app/pages/payment/over-payment/index.vue?macro=true";
import { default as editAANevZqv8ZMeta } from "/vercel/path0/.app/pages/payment/payment-service/[id]/edit.vue?macro=true";
import { default as _91id_93QcrRBH1a7pMeta } from "/vercel/path0/.app/pages/payment/payment-service/[id].vue?macro=true";
import { default as addXDDmU2GDVLMeta } from "/vercel/path0/.app/pages/payment/payment-service/add.vue?macro=true";
import { default as importsZCYXbNs4DMeta } from "/vercel/path0/.app/pages/payment/payment-service/import.vue?macro=true";
import { default as indexOPlBEcVsPnMeta } from "/vercel/path0/.app/pages/payment/payment-service/index.vue?macro=true";
import { default as editM5IJuP4PQXMeta } from "/vercel/path0/.app/pages/payment/pre-payment/[id]/edit.vue?macro=true";
import { default as _91id_93ueGuutu9v7Meta } from "/vercel/path0/.app/pages/payment/pre-payment/[id].vue?macro=true";
import { default as addZ43pM3Vpf1Meta } from "/vercel/path0/.app/pages/payment/pre-payment/add.vue?macro=true";
import { default as importPeE8y8SRDRMeta } from "/vercel/path0/.app/pages/payment/pre-payment/import.vue?macro=true";
import { default as indexumAGFkVCQeMeta } from "/vercel/path0/.app/pages/payment/pre-payment/index.vue?macro=true";
import { default as editD2cYi0RvNqMeta } from "/vercel/path0/.app/pages/purchase/bill/[id]/edit.vue?macro=true";
import { default as _91id_93T4xtKNpOwPMeta } from "/vercel/path0/.app/pages/purchase/bill/[id].vue?macro=true";
import { default as addhHArN1BsK1Meta } from "/vercel/path0/.app/pages/purchase/bill/add.vue?macro=true";
import { default as import0cms63hRIcMeta } from "/vercel/path0/.app/pages/purchase/bill/import.vue?macro=true";
import { default as indexncsyrSf8FYMeta } from "/vercel/path0/.app/pages/purchase/bill/index.vue?macro=true";
import { default as editrh5GLffeCXMeta } from "/vercel/path0/.app/pages/purchase/recurring-bill/[id]/edit.vue?macro=true";
import { default as _91id_93cKZ0zmEHztMeta } from "/vercel/path0/.app/pages/purchase/recurring-bill/[id].vue?macro=true";
import { default as addIKR7YJ1ntzMeta } from "/vercel/path0/.app/pages/purchase/recurring-bill/add.vue?macro=true";
import { default as import6P3aFiEEtPMeta } from "/vercel/path0/.app/pages/purchase/recurring-bill/import.vue?macro=true";
import { default as indexe9YRAYlOaDMeta } from "/vercel/path0/.app/pages/purchase/recurring-bill/index.vue?macro=true";
import { default as editQzULHtRzPyMeta } from "/vercel/path0/.app/pages/purchase/vendor-credit/[id]/edit.vue?macro=true";
import { default as _91id_93DZ9TKRcP4FMeta } from "/vercel/path0/.app/pages/purchase/vendor-credit/[id].vue?macro=true";
import { default as addUv82UZTumJMeta } from "/vercel/path0/.app/pages/purchase/vendor-credit/add.vue?macro=true";
import { default as importKog4dFafzzMeta } from "/vercel/path0/.app/pages/purchase/vendor-credit/import.vue?macro=true";
import { default as indexvwH1xF4n8SMeta } from "/vercel/path0/.app/pages/purchase/vendor-credit/index.vue?macro=true";
import { default as editM0HYoI7vHQMeta } from "/vercel/path0/.app/pages/sale/credit-note/[id]/edit.vue?macro=true";
import { default as _91id_939V4ARVBInLMeta } from "/vercel/path0/.app/pages/sale/credit-note/[id].vue?macro=true";
import { default as addpQeQJf1ntnMeta } from "/vercel/path0/.app/pages/sale/credit-note/add.vue?macro=true";
import { default as import3h5pjpex2AMeta } from "/vercel/path0/.app/pages/sale/credit-note/import.vue?macro=true";
import { default as indexAjIil7OPfUMeta } from "/vercel/path0/.app/pages/sale/credit-note/index.vue?macro=true";
import { default as edit7ZoZJBr2lEMeta } from "/vercel/path0/.app/pages/sale/estimate/[id]/edit.vue?macro=true";
import { default as _91id_93qlrPfak4xSMeta } from "/vercel/path0/.app/pages/sale/estimate/[id].vue?macro=true";
import { default as addRarV2EqdIrMeta } from "/vercel/path0/.app/pages/sale/estimate/add.vue?macro=true";
import { default as importtPNRgnXtikMeta } from "/vercel/path0/.app/pages/sale/estimate/import.vue?macro=true";
import { default as indexsT9TIwtnMYMeta } from "/vercel/path0/.app/pages/sale/estimate/index.vue?macro=true";
import { default as editCP950n5oFdMeta } from "/vercel/path0/.app/pages/sale/invoice/[id]/edit.vue?macro=true";
import { default as _91id_93YPrcBC3y3vMeta } from "/vercel/path0/.app/pages/sale/invoice/[id].vue?macro=true";
import { default as addqpIeJgYHZtMeta } from "/vercel/path0/.app/pages/sale/invoice/add.vue?macro=true";
import { default as importj6N7Q9LtIiMeta } from "/vercel/path0/.app/pages/sale/invoice/import.vue?macro=true";
import { default as indexrh5eGOa4oXMeta } from "/vercel/path0/.app/pages/sale/invoice/index.vue?macro=true";
import { default as editIxKdK4ebTgMeta } from "/vercel/path0/.app/pages/sale/order/[id]/edit.vue?macro=true";
import { default as _91id_93eMOkg5ocmfMeta } from "/vercel/path0/.app/pages/sale/order/[id].vue?macro=true";
import { default as addFJop38bdKIMeta } from "/vercel/path0/.app/pages/sale/order/add.vue?macro=true";
import { default as importVJDWfUPeMNMeta } from "/vercel/path0/.app/pages/sale/order/import.vue?macro=true";
import { default as indexirPoMIJU22Meta } from "/vercel/path0/.app/pages/sale/order/index.vue?macro=true";
import { default as editGG1WAUtsRpMeta } from "/vercel/path0/.app/pages/sale/recurring-invoice/[id]/edit.vue?macro=true";
import { default as _91id_93GbUy4AtJQGMeta } from "/vercel/path0/.app/pages/sale/recurring-invoice/[id].vue?macro=true";
import { default as addLe5Fuawc3LMeta } from "/vercel/path0/.app/pages/sale/recurring-invoice/add.vue?macro=true";
import { default as importKVTbM4C9GEMeta } from "/vercel/path0/.app/pages/sale/recurring-invoice/import.vue?macro=true";
import { default as indexMf9NsWzi48Meta } from "/vercel/path0/.app/pages/sale/recurring-invoice/index.vue?macro=true";
import { default as editot5v9mYWloMeta } from "/vercel/path0/.app/pages/tax/tax-authority/[id]/edit.vue?macro=true";
import { default as _91id_93yZ4BXyG8WyMeta } from "/vercel/path0/.app/pages/tax/tax-authority/[id].vue?macro=true";
import { default as add8WqLM6TKXcMeta } from "/vercel/path0/.app/pages/tax/tax-authority/add.vue?macro=true";
import { default as importJktsOjPaisMeta } from "/vercel/path0/.app/pages/tax/tax-authority/import.vue?macro=true";
import { default as indexojqYb8TOExMeta } from "/vercel/path0/.app/pages/tax/tax-authority/index.vue?macro=true";
import { default as editBLboUieLhkMeta } from "/vercel/path0/.app/pages/tax/tax-exemption/[id]/edit.vue?macro=true";
import { default as _91id_93GLfDMkiLUJMeta } from "/vercel/path0/.app/pages/tax/tax-exemption/[id].vue?macro=true";
import { default as addVSzEa13ALiMeta } from "/vercel/path0/.app/pages/tax/tax-exemption/add.vue?macro=true";
import { default as importuVbO3Af1mfMeta } from "/vercel/path0/.app/pages/tax/tax-exemption/import.vue?macro=true";
import { default as indexUsGPuv6ZEVMeta } from "/vercel/path0/.app/pages/tax/tax-exemption/index.vue?macro=true";
import { default as editbHqft4cVEEMeta } from "/vercel/path0/.app/pages/tax/tax-rate/[id]/edit.vue?macro=true";
import { default as _91id_9379in7Ad1k1Meta } from "/vercel/path0/.app/pages/tax/tax-rate/[id].vue?macro=true";
import { default as addSZVi7QFKJuMeta } from "/vercel/path0/.app/pages/tax/tax-rate/add.vue?macro=true";
import { default as importwRQUyjVarnMeta } from "/vercel/path0/.app/pages/tax/tax-rate/import.vue?macro=true";
import { default as indexlsenR1TytcMeta } from "/vercel/path0/.app/pages/tax/tax-rate/index.vue?macro=true";
import { default as editWo9HPFArDlMeta } from "/vercel/path0/.app/pages/tax/tax/[id]/edit.vue?macro=true";
import { default as _91id_93ovLHKhEk9rMeta } from "/vercel/path0/.app/pages/tax/tax/[id].vue?macro=true";
import { default as addI8TsDyranCMeta } from "/vercel/path0/.app/pages/tax/tax/add.vue?macro=true";
import { default as importvmbSVVNhIUMeta } from "/vercel/path0/.app/pages/tax/tax/import.vue?macro=true";
import { default as indexMidc7FMhgbMeta } from "/vercel/path0/.app/pages/tax/tax/index.vue?macro=true";
import { default as user_45managementkNAqgHt6GAMeta } from "/vercel/path0/.app/pages/user-management.vue?macro=true";
export default [
  {
    name: _91id_93XVWKvZYnt5Meta?.name ?? "account-account-type-locking-id",
    path: _91id_93XVWKvZYnt5Meta?.path ?? "/account/account-type-locking/:id()",
    children: [
  {
    name: editTkREmjReaUMeta?.name ?? "account-account-type-locking-id-edit",
    path: editTkREmjReaUMeta?.path ?? "edit",
    meta: editTkREmjReaUMeta || {},
    alias: editTkREmjReaUMeta?.alias || [],
    redirect: editTkREmjReaUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/account-type-locking/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93XVWKvZYnt5Meta || {},
    alias: _91id_93XVWKvZYnt5Meta?.alias || [],
    redirect: _91id_93XVWKvZYnt5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/account-type-locking/[id].vue").then(m => m.default || m)
  },
  {
    name: addyOvo4q5UbVMeta?.name ?? "account-account-type-locking-add",
    path: addyOvo4q5UbVMeta?.path ?? "/account/account-type-locking/add",
    meta: addyOvo4q5UbVMeta || {},
    alias: addyOvo4q5UbVMeta?.alias || [],
    redirect: addyOvo4q5UbVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/account-type-locking/add.vue").then(m => m.default || m)
  },
  {
    name: importh6pIYCI6JoMeta?.name ?? "account-account-type-locking-import",
    path: importh6pIYCI6JoMeta?.path ?? "/account/account-type-locking/import",
    meta: importh6pIYCI6JoMeta || {},
    alias: importh6pIYCI6JoMeta?.alias || [],
    redirect: importh6pIYCI6JoMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/account-type-locking/import.vue").then(m => m.default || m)
  },
  {
    name: indexDv7VqDA2zfMeta?.name ?? "account-account-type-locking",
    path: indexDv7VqDA2zfMeta?.path ?? "/account/account-type-locking",
    meta: indexDv7VqDA2zfMeta || {},
    alias: indexDv7VqDA2zfMeta?.alias || [],
    redirect: indexDv7VqDA2zfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/account-type-locking/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zmRrVJjl1zMeta?.name ?? "account-account-type-id",
    path: _91id_93zmRrVJjl1zMeta?.path ?? "/account/account-type/:id()",
    children: [
  {
    name: edit4aljphxYGiMeta?.name ?? "account-account-type-id-edit",
    path: edit4aljphxYGiMeta?.path ?? "edit",
    meta: edit4aljphxYGiMeta || {},
    alias: edit4aljphxYGiMeta?.alias || [],
    redirect: edit4aljphxYGiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/account-type/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93zmRrVJjl1zMeta || {},
    alias: _91id_93zmRrVJjl1zMeta?.alias || [],
    redirect: _91id_93zmRrVJjl1zMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/account-type/[id].vue").then(m => m.default || m)
  },
  {
    name: addSFf159StMkMeta?.name ?? "account-account-type-add",
    path: addSFf159StMkMeta?.path ?? "/account/account-type/add",
    meta: addSFf159StMkMeta || {},
    alias: addSFf159StMkMeta?.alias || [],
    redirect: addSFf159StMkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/account-type/add.vue").then(m => m.default || m)
  },
  {
    name: importVONo2fyeDtMeta?.name ?? "account-account-type-import",
    path: importVONo2fyeDtMeta?.path ?? "/account/account-type/import",
    meta: importVONo2fyeDtMeta || {},
    alias: importVONo2fyeDtMeta?.alias || [],
    redirect: importVONo2fyeDtMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/account-type/import.vue").then(m => m.default || m)
  },
  {
    name: index7GCSTpC9ZfMeta?.name ?? "account-account-type",
    path: index7GCSTpC9ZfMeta?.path ?? "/account/account-type",
    meta: index7GCSTpC9ZfMeta || {},
    alias: index7GCSTpC9ZfMeta?.alias || [],
    redirect: index7GCSTpC9ZfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/account-type/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pUqIZmZ9MXMeta?.name ?? "account-account-id",
    path: _91id_93pUqIZmZ9MXMeta?.path ?? "/account/account/:id()",
    children: [
  {
    name: edithD3HFuSnpjMeta?.name ?? "account-account-id-edit",
    path: edithD3HFuSnpjMeta?.path ?? "edit",
    meta: edithD3HFuSnpjMeta || {},
    alias: edithD3HFuSnpjMeta?.alias || [],
    redirect: edithD3HFuSnpjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/account/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: viewBwUHarRgy1Meta?.name ?? "account-account-id-view",
    path: viewBwUHarRgy1Meta?.path ?? "view",
    meta: viewBwUHarRgy1Meta || {},
    alias: viewBwUHarRgy1Meta?.alias || [],
    redirect: viewBwUHarRgy1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/account/[id]/view.vue").then(m => m.default || m)
  }
],
    meta: _91id_93pUqIZmZ9MXMeta || {},
    alias: _91id_93pUqIZmZ9MXMeta?.alias || [],
    redirect: _91id_93pUqIZmZ9MXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/account/[id].vue").then(m => m.default || m)
  },
  {
    name: adde52jd8132VMeta?.name ?? "account-account-add",
    path: adde52jd8132VMeta?.path ?? "/account/account/add",
    meta: adde52jd8132VMeta || {},
    alias: adde52jd8132VMeta?.alias || [],
    redirect: adde52jd8132VMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/account/add.vue").then(m => m.default || m)
  },
  {
    name: import2KXn16g5NOMeta?.name ?? "account-account-import",
    path: import2KXn16g5NOMeta?.path ?? "/account/account/import",
    meta: import2KXn16g5NOMeta || {},
    alias: import2KXn16g5NOMeta?.alias || [],
    redirect: import2KXn16g5NOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/account/import.vue").then(m => m.default || m)
  },
  {
    name: index9XIpfAyCwBMeta?.name ?? "account-account",
    path: index9XIpfAyCwBMeta?.path ?? "/account/account",
    meta: index9XIpfAyCwBMeta || {},
    alias: index9XIpfAyCwBMeta?.alias || [],
    redirect: index9XIpfAyCwBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93eZhMXlHOMZMeta?.name ?? "account-bank-account-id",
    path: _91id_93eZhMXlHOMZMeta?.path ?? "/account/bank-account/:id()",
    children: [
  {
    name: editj9DayzEm85Meta?.name ?? "account-bank-account-id-edit",
    path: editj9DayzEm85Meta?.path ?? "edit",
    meta: editj9DayzEm85Meta || {},
    alias: editj9DayzEm85Meta?.alias || [],
    redirect: editj9DayzEm85Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/bank-account/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93eZhMXlHOMZMeta || {},
    alias: _91id_93eZhMXlHOMZMeta?.alias || [],
    redirect: _91id_93eZhMXlHOMZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/bank-account/[id].vue").then(m => m.default || m)
  },
  {
    name: addS7ES3wtZa9Meta?.name ?? "account-bank-account-add",
    path: addS7ES3wtZa9Meta?.path ?? "/account/bank-account/add",
    meta: addS7ES3wtZa9Meta || {},
    alias: addS7ES3wtZa9Meta?.alias || [],
    redirect: addS7ES3wtZa9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/bank-account/add.vue").then(m => m.default || m)
  },
  {
    name: importDERahiBjdtMeta?.name ?? "account-bank-account-import",
    path: importDERahiBjdtMeta?.path ?? "/account/bank-account/import",
    meta: importDERahiBjdtMeta || {},
    alias: importDERahiBjdtMeta?.alias || [],
    redirect: importDERahiBjdtMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/bank-account/import.vue").then(m => m.default || m)
  },
  {
    name: indextrWy2NLrvQMeta?.name ?? "account-bank-account",
    path: indextrWy2NLrvQMeta?.path ?? "/account/bank-account",
    meta: indextrWy2NLrvQMeta || {},
    alias: indextrWy2NLrvQMeta?.alias || [],
    redirect: indextrWy2NLrvQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/bank-account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93gwhR4IiRZ9Meta?.name ?? "account-budget-category-id",
    path: _91id_93gwhR4IiRZ9Meta?.path ?? "/account/budget-category/:id()",
    children: [
  {
    name: editOxDgDfkoLDMeta?.name ?? "account-budget-category-id-edit",
    path: editOxDgDfkoLDMeta?.path ?? "edit",
    meta: editOxDgDfkoLDMeta || {},
    alias: editOxDgDfkoLDMeta?.alias || [],
    redirect: editOxDgDfkoLDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/budget-category/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93gwhR4IiRZ9Meta || {},
    alias: _91id_93gwhR4IiRZ9Meta?.alias || [],
    redirect: _91id_93gwhR4IiRZ9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/budget-category/[id].vue").then(m => m.default || m)
  },
  {
    name: addN65L7s0orhMeta?.name ?? "account-budget-category-add",
    path: addN65L7s0orhMeta?.path ?? "/account/budget-category/add",
    meta: addN65L7s0orhMeta || {},
    alias: addN65L7s0orhMeta?.alias || [],
    redirect: addN65L7s0orhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/budget-category/add.vue").then(m => m.default || m)
  },
  {
    name: importj4MX6Rz7aZMeta?.name ?? "account-budget-category-import",
    path: importj4MX6Rz7aZMeta?.path ?? "/account/budget-category/import",
    meta: importj4MX6Rz7aZMeta || {},
    alias: importj4MX6Rz7aZMeta?.alias || [],
    redirect: importj4MX6Rz7aZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/budget-category/import.vue").then(m => m.default || m)
  },
  {
    name: indexJDnjQmEC8rMeta?.name ?? "account-budget-category",
    path: indexJDnjQmEC8rMeta?.path ?? "/account/budget-category",
    meta: indexJDnjQmEC8rMeta || {},
    alias: indexJDnjQmEC8rMeta?.alias || [],
    redirect: indexJDnjQmEC8rMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/budget-category/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93W2uKn7tv0RMeta?.name ?? "account-budget-id",
    path: _91id_93W2uKn7tv0RMeta?.path ?? "/account/budget/:id()",
    children: [
  {
    name: edit25e8w0HClVMeta?.name ?? "account-budget-id-edit",
    path: edit25e8w0HClVMeta?.path ?? "edit",
    meta: edit25e8w0HClVMeta || {},
    alias: edit25e8w0HClVMeta?.alias || [],
    redirect: edit25e8w0HClVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/budget/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93W2uKn7tv0RMeta || {},
    alias: _91id_93W2uKn7tv0RMeta?.alias || [],
    redirect: _91id_93W2uKn7tv0RMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/budget/[id].vue").then(m => m.default || m)
  },
  {
    name: addhO1SDlqv7fMeta?.name ?? "account-budget-add",
    path: addhO1SDlqv7fMeta?.path ?? "/account/budget/add",
    meta: addhO1SDlqv7fMeta || {},
    alias: addhO1SDlqv7fMeta?.alias || [],
    redirect: addhO1SDlqv7fMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/budget/add.vue").then(m => m.default || m)
  },
  {
    name: importt9GgEKNcYYMeta?.name ?? "account-budget-import",
    path: importt9GgEKNcYYMeta?.path ?? "/account/budget/import",
    meta: importt9GgEKNcYYMeta || {},
    alias: importt9GgEKNcYYMeta?.alias || [],
    redirect: importt9GgEKNcYYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/budget/import.vue").then(m => m.default || m)
  },
  {
    name: indexXbxPwdvlGMMeta?.name ?? "account-budget",
    path: indexXbxPwdvlGMMeta?.path ?? "/account/budget",
    meta: indexXbxPwdvlGMMeta || {},
    alias: indexXbxPwdvlGMMeta?.alias || [],
    redirect: indexXbxPwdvlGMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/budget/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bbnDYsNCEMMeta?.name ?? "account-currency-adjustment-id",
    path: _91id_93bbnDYsNCEMMeta?.path ?? "/account/currency-adjustment/:id()",
    children: [
  {
    name: editjTbWqVyZuKMeta?.name ?? "account-currency-adjustment-id-edit",
    path: editjTbWqVyZuKMeta?.path ?? "edit",
    meta: editjTbWqVyZuKMeta || {},
    alias: editjTbWqVyZuKMeta?.alias || [],
    redirect: editjTbWqVyZuKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/currency-adjustment/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93bbnDYsNCEMMeta || {},
    alias: _91id_93bbnDYsNCEMMeta?.alias || [],
    redirect: _91id_93bbnDYsNCEMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/currency-adjustment/[id].vue").then(m => m.default || m)
  },
  {
    name: addxRzUmrwSO3Meta?.name ?? "account-currency-adjustment-add",
    path: addxRzUmrwSO3Meta?.path ?? "/account/currency-adjustment/add",
    meta: addxRzUmrwSO3Meta || {},
    alias: addxRzUmrwSO3Meta?.alias || [],
    redirect: addxRzUmrwSO3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/currency-adjustment/add.vue").then(m => m.default || m)
  },
  {
    name: importn6c01juexLMeta?.name ?? "account-currency-adjustment-import",
    path: importn6c01juexLMeta?.path ?? "/account/currency-adjustment/import",
    meta: importn6c01juexLMeta || {},
    alias: importn6c01juexLMeta?.alias || [],
    redirect: importn6c01juexLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/currency-adjustment/import.vue").then(m => m.default || m)
  },
  {
    name: indexx5Dq98EdeCMeta?.name ?? "account-currency-adjustment",
    path: indexx5Dq98EdeCMeta?.path ?? "/account/currency-adjustment",
    meta: indexx5Dq98EdeCMeta || {},
    alias: indexx5Dq98EdeCMeta?.alias || [],
    redirect: indexx5Dq98EdeCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/currency-adjustment/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pgbBStsllcMeta?.name ?? "account-current-asset-id",
    path: _91id_93pgbBStsllcMeta?.path ?? "/account/current-asset/:id()",
    children: [
  {
    name: edit2TBrNg517rMeta?.name ?? "account-current-asset-id-edit",
    path: edit2TBrNg517rMeta?.path ?? "edit",
    meta: edit2TBrNg517rMeta || {},
    alias: edit2TBrNg517rMeta?.alias || [],
    redirect: edit2TBrNg517rMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/current-asset/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93pgbBStsllcMeta || {},
    alias: _91id_93pgbBStsllcMeta?.alias || [],
    redirect: _91id_93pgbBStsllcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/current-asset/[id].vue").then(m => m.default || m)
  },
  {
    name: addsuABl5pGP0Meta?.name ?? "account-current-asset-add",
    path: addsuABl5pGP0Meta?.path ?? "/account/current-asset/add",
    meta: addsuABl5pGP0Meta || {},
    alias: addsuABl5pGP0Meta?.alias || [],
    redirect: addsuABl5pGP0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/current-asset/add.vue").then(m => m.default || m)
  },
  {
    name: importFvrE0KncFZMeta?.name ?? "account-current-asset-import",
    path: importFvrE0KncFZMeta?.path ?? "/account/current-asset/import",
    meta: importFvrE0KncFZMeta || {},
    alias: importFvrE0KncFZMeta?.alias || [],
    redirect: importFvrE0KncFZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/current-asset/import.vue").then(m => m.default || m)
  },
  {
    name: indexUtUwt4qyTHMeta?.name ?? "account-current-asset",
    path: indexUtUwt4qyTHMeta?.path ?? "/account/current-asset",
    meta: indexUtUwt4qyTHMeta || {},
    alias: indexUtUwt4qyTHMeta?.alias || [],
    redirect: indexUtUwt4qyTHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/current-asset/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_930tigiEX4AgMeta?.name ?? "account-exchange-rate-id",
    path: _91id_930tigiEX4AgMeta?.path ?? "/account/exchange-rate/:id()",
    children: [
  {
    name: editrsftrifo8FMeta?.name ?? "account-exchange-rate-id-edit",
    path: editrsftrifo8FMeta?.path ?? "edit",
    meta: editrsftrifo8FMeta || {},
    alias: editrsftrifo8FMeta?.alias || [],
    redirect: editrsftrifo8FMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/exchange-rate/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_930tigiEX4AgMeta || {},
    alias: _91id_930tigiEX4AgMeta?.alias || [],
    redirect: _91id_930tigiEX4AgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/exchange-rate/[id].vue").then(m => m.default || m)
  },
  {
    name: addrogm1cIi9ZMeta?.name ?? "account-exchange-rate-add",
    path: addrogm1cIi9ZMeta?.path ?? "/account/exchange-rate/add",
    meta: addrogm1cIi9ZMeta || {},
    alias: addrogm1cIi9ZMeta?.alias || [],
    redirect: addrogm1cIi9ZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/exchange-rate/add.vue").then(m => m.default || m)
  },
  {
    name: importRdxBRPxtqnMeta?.name ?? "account-exchange-rate-import",
    path: importRdxBRPxtqnMeta?.path ?? "/account/exchange-rate/import",
    meta: importRdxBRPxtqnMeta || {},
    alias: importRdxBRPxtqnMeta?.alias || [],
    redirect: importRdxBRPxtqnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/exchange-rate/import.vue").then(m => m.default || m)
  },
  {
    name: indexJ0SMekblRmMeta?.name ?? "account-exchange-rate",
    path: indexJ0SMekblRmMeta?.path ?? "/account/exchange-rate",
    meta: indexJ0SMekblRmMeta || {},
    alias: indexJ0SMekblRmMeta?.alias || [],
    redirect: indexJ0SMekblRmMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/exchange-rate/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93efZCVHKC2hMeta?.name ?? "account-fixed-asset-disposal-id",
    path: _91id_93efZCVHKC2hMeta?.path ?? "/account/fixed-asset-disposal/:id()",
    children: [
  {
    name: editQZOS5GOEkAMeta?.name ?? "account-fixed-asset-disposal-id-edit",
    path: editQZOS5GOEkAMeta?.path ?? "edit",
    meta: editQZOS5GOEkAMeta || {},
    alias: editQZOS5GOEkAMeta?.alias || [],
    redirect: editQZOS5GOEkAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/fixed-asset-disposal/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93efZCVHKC2hMeta || {},
    alias: _91id_93efZCVHKC2hMeta?.alias || [],
    redirect: _91id_93efZCVHKC2hMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/fixed-asset-disposal/[id].vue").then(m => m.default || m)
  },
  {
    name: addXjJ29eKzGqMeta?.name ?? "account-fixed-asset-disposal-add",
    path: addXjJ29eKzGqMeta?.path ?? "/account/fixed-asset-disposal/add",
    meta: addXjJ29eKzGqMeta || {},
    alias: addXjJ29eKzGqMeta?.alias || [],
    redirect: addXjJ29eKzGqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/fixed-asset-disposal/add.vue").then(m => m.default || m)
  },
  {
    name: importuNbAFvfOYYMeta?.name ?? "account-fixed-asset-disposal-import",
    path: importuNbAFvfOYYMeta?.path ?? "/account/fixed-asset-disposal/import",
    meta: importuNbAFvfOYYMeta || {},
    alias: importuNbAFvfOYYMeta?.alias || [],
    redirect: importuNbAFvfOYYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/fixed-asset-disposal/import.vue").then(m => m.default || m)
  },
  {
    name: indexUZeE6AY4kBMeta?.name ?? "account-fixed-asset-disposal",
    path: indexUZeE6AY4kBMeta?.path ?? "/account/fixed-asset-disposal",
    meta: indexUZeE6AY4kBMeta || {},
    alias: indexUZeE6AY4kBMeta?.alias || [],
    redirect: indexUZeE6AY4kBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/fixed-asset-disposal/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HnziyJAVzYMeta?.name ?? "account-fixed-asset-nbv-id",
    path: _91id_93HnziyJAVzYMeta?.path ?? "/account/fixed-asset-nbv/:id()",
    children: [
  {
    name: editIJLYKJC11NMeta?.name ?? "account-fixed-asset-nbv-id-edit",
    path: editIJLYKJC11NMeta?.path ?? "edit",
    meta: editIJLYKJC11NMeta || {},
    alias: editIJLYKJC11NMeta?.alias || [],
    redirect: editIJLYKJC11NMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/fixed-asset-nbv/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93HnziyJAVzYMeta || {},
    alias: _91id_93HnziyJAVzYMeta?.alias || [],
    redirect: _91id_93HnziyJAVzYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/fixed-asset-nbv/[id].vue").then(m => m.default || m)
  },
  {
    name: add1jXIpMHoIgMeta?.name ?? "account-fixed-asset-nbv-add",
    path: add1jXIpMHoIgMeta?.path ?? "/account/fixed-asset-nbv/add",
    meta: add1jXIpMHoIgMeta || {},
    alias: add1jXIpMHoIgMeta?.alias || [],
    redirect: add1jXIpMHoIgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/fixed-asset-nbv/add.vue").then(m => m.default || m)
  },
  {
    name: importSu59JToZpnMeta?.name ?? "account-fixed-asset-nbv-import",
    path: importSu59JToZpnMeta?.path ?? "/account/fixed-asset-nbv/import",
    meta: importSu59JToZpnMeta || {},
    alias: importSu59JToZpnMeta?.alias || [],
    redirect: importSu59JToZpnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/fixed-asset-nbv/import.vue").then(m => m.default || m)
  },
  {
    name: indexL0RTPVM2LiMeta?.name ?? "account-fixed-asset-nbv",
    path: indexL0RTPVM2LiMeta?.path ?? "/account/fixed-asset-nbv",
    meta: indexL0RTPVM2LiMeta || {},
    alias: indexL0RTPVM2LiMeta?.alias || [],
    redirect: indexL0RTPVM2LiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/fixed-asset-nbv/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nnzz0pZTP2Meta?.name ?? "account-fixed-asset-id",
    path: _91id_93nnzz0pZTP2Meta?.path ?? "/account/fixed-asset/:id()",
    children: [
  {
    name: edito3R6vBtqHIMeta?.name ?? "account-fixed-asset-id-edit",
    path: edito3R6vBtqHIMeta?.path ?? "edit",
    meta: edito3R6vBtqHIMeta || {},
    alias: edito3R6vBtqHIMeta?.alias || [],
    redirect: edito3R6vBtqHIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/fixed-asset/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93nnzz0pZTP2Meta || {},
    alias: _91id_93nnzz0pZTP2Meta?.alias || [],
    redirect: _91id_93nnzz0pZTP2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/fixed-asset/[id].vue").then(m => m.default || m)
  },
  {
    name: addKvq6bbTfvjMeta?.name ?? "account-fixed-asset-add",
    path: addKvq6bbTfvjMeta?.path ?? "/account/fixed-asset/add",
    meta: addKvq6bbTfvjMeta || {},
    alias: addKvq6bbTfvjMeta?.alias || [],
    redirect: addKvq6bbTfvjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/fixed-asset/add.vue").then(m => m.default || m)
  },
  {
    name: importxCv1Qy0eoXMeta?.name ?? "account-fixed-asset-import",
    path: importxCv1Qy0eoXMeta?.path ?? "/account/fixed-asset/import",
    meta: importxCv1Qy0eoXMeta || {},
    alias: importxCv1Qy0eoXMeta?.alias || [],
    redirect: importxCv1Qy0eoXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/fixed-asset/import.vue").then(m => m.default || m)
  },
  {
    name: indexCm7PDDvYqCMeta?.name ?? "account-fixed-asset",
    path: indexCm7PDDvYqCMeta?.path ?? "/account/fixed-asset",
    meta: indexCm7PDDvYqCMeta || {},
    alias: indexCm7PDDvYqCMeta?.alias || [],
    redirect: indexCm7PDDvYqCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/account/fixed-asset/index.vue").then(m => m.default || m)
  },
  {
    name: accounting_45systemMRAajerrdgMeta?.name ?? "accounting-system",
    path: accounting_45systemMRAajerrdgMeta?.path ?? "/accounting-system",
    meta: accounting_45systemMRAajerrdgMeta || {},
    alias: accounting_45systemMRAajerrdgMeta?.alias || [],
    redirect: accounting_45systemMRAajerrdgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/accounting-system.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordjZ3b3vrR5GMeta?.name ?? "auth-forgot-password",
    path: forgot_45passwordjZ3b3vrR5GMeta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordjZ3b3vrR5GMeta || {},
    alias: forgot_45passwordjZ3b3vrR5GMeta?.alias || [],
    redirect: forgot_45passwordjZ3b3vrR5GMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: gen_452faBdAXBQgrzkMeta?.name ?? "auth-gen-2fa",
    path: gen_452faBdAXBQgrzkMeta?.path ?? "/auth/gen-2fa",
    meta: gen_452faBdAXBQgrzkMeta || {},
    alias: gen_452faBdAXBQgrzkMeta?.alias || [],
    redirect: gen_452faBdAXBQgrzkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/auth/gen-2fa.vue").then(m => m.default || m)
  },
  {
    name: gen_45recovery_452fail2sc97e3pMeta?.name ?? "auth-gen-recovery-2fa",
    path: gen_45recovery_452fail2sc97e3pMeta?.path ?? "/auth/gen-recovery-2fa",
    meta: gen_45recovery_452fail2sc97e3pMeta || {},
    alias: gen_45recovery_452fail2sc97e3pMeta?.alias || [],
    redirect: gen_45recovery_452fail2sc97e3pMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/auth/gen-recovery-2fa.vue").then(m => m.default || m)
  },
  {
    name: indexPkY6dMCrINMeta?.name ?? "auth-profile",
    path: indexPkY6dMCrINMeta?.path ?? "/auth/profile",
    meta: indexPkY6dMCrINMeta || {},
    alias: indexPkY6dMCrINMeta?.alias || [],
    redirect: indexPkY6dMCrINMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/auth/profile/index.vue").then(m => m.default || m)
  },
  {
    name: settingsGG1svV8sUzMeta?.name ?? "auth-profile-settings",
    path: settingsGG1svV8sUzMeta?.path ?? "/auth/profile/settings",
    meta: settingsGG1svV8sUzMeta || {},
    alias: settingsGG1svV8sUzMeta?.alias || [],
    redirect: settingsGG1svV8sUzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/auth/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: recovery_452fa32nhAskNw6Meta?.name ?? "auth-recovery-2fa",
    path: recovery_452fa32nhAskNw6Meta?.path ?? "/auth/recovery-2fa",
    meta: recovery_452fa32nhAskNw6Meta || {},
    alias: recovery_452fa32nhAskNw6Meta?.alias || [],
    redirect: recovery_452fa32nhAskNw6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/auth/recovery-2fa.vue").then(m => m.default || m)
  },
  {
    name: signinUKjnmwAHe9Meta?.name ?? "auth-signin",
    path: signinUKjnmwAHe9Meta?.path ?? "/auth/signin",
    meta: signinUKjnmwAHe9Meta || {},
    alias: signinUKjnmwAHe9Meta?.alias || [],
    redirect: signinUKjnmwAHe9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: signup71CxJQKKCmMeta?.name ?? "auth-signup",
    path: signup71CxJQKKCmMeta?.path ?? "/auth/signup",
    meta: signup71CxJQKKCmMeta || {},
    alias: signup71CxJQKKCmMeta?.alias || [],
    redirect: signup71CxJQKKCmMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: verify_452faWHAbR753qhMeta?.name ?? "auth-verify-2fa",
    path: verify_452faWHAbR753qhMeta?.path ?? "/auth/verify-2fa",
    meta: verify_452faWHAbR753qhMeta || {},
    alias: verify_452faWHAbR753qhMeta?.alias || [],
    redirect: verify_452faWHAbR753qhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/auth/verify-2fa.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailtU7V9OEQhCMeta?.name ?? "auth-verify-email",
    path: verify_45emailtU7V9OEQhCMeta?.path ?? "/auth/verify-email",
    meta: verify_45emailtU7V9OEQhCMeta || {},
    alias: verify_45emailtU7V9OEQhCMeta?.alias || [],
    redirect: verify_45emailtU7V9OEQhCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: verify_45recovery_452fa5MGQAYzNb4Meta?.name ?? "auth-verify-recovery-2fa",
    path: verify_45recovery_452fa5MGQAYzNb4Meta?.path ?? "/auth/verify-recovery-2fa",
    meta: verify_45recovery_452fa5MGQAYzNb4Meta || {},
    alias: verify_45recovery_452fa5MGQAYzNb4Meta?.alias || [],
    redirect: verify_45recovery_452fa5MGQAYzNb4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/auth/verify-recovery-2fa.vue").then(m => m.default || m)
  },
  {
    name: _91id_938yCdUFAcW1Meta?.name ?? "bank-bank-adjustment-id",
    path: _91id_938yCdUFAcW1Meta?.path ?? "/bank/bank-adjustment/:id()",
    children: [
  {
    name: editoL60En2bOBMeta?.name ?? "bank-bank-adjustment-id-edit",
    path: editoL60En2bOBMeta?.path ?? "edit",
    meta: editoL60En2bOBMeta || {},
    alias: editoL60En2bOBMeta?.alias || [],
    redirect: editoL60En2bOBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/bank/bank-adjustment/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_938yCdUFAcW1Meta || {},
    alias: _91id_938yCdUFAcW1Meta?.alias || [],
    redirect: _91id_938yCdUFAcW1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/bank/bank-adjustment/[id].vue").then(m => m.default || m)
  },
  {
    name: addkAl70iEydKMeta?.name ?? "bank-bank-adjustment-add",
    path: addkAl70iEydKMeta?.path ?? "/bank/bank-adjustment/add",
    meta: addkAl70iEydKMeta || {},
    alias: addkAl70iEydKMeta?.alias || [],
    redirect: addkAl70iEydKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/bank/bank-adjustment/add.vue").then(m => m.default || m)
  },
  {
    name: importoSsMfN1LMYMeta?.name ?? "bank-bank-adjustment-import",
    path: importoSsMfN1LMYMeta?.path ?? "/bank/bank-adjustment/import",
    meta: importoSsMfN1LMYMeta || {},
    alias: importoSsMfN1LMYMeta?.alias || [],
    redirect: importoSsMfN1LMYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/bank/bank-adjustment/import.vue").then(m => m.default || m)
  },
  {
    name: indexlWyGanzRvEMeta?.name ?? "bank-bank-adjustment",
    path: indexlWyGanzRvEMeta?.path ?? "/bank/bank-adjustment",
    meta: indexlWyGanzRvEMeta || {},
    alias: indexlWyGanzRvEMeta?.alias || [],
    redirect: indexlWyGanzRvEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/bank/bank-adjustment/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93M3QtmwluqJMeta?.name ?? "bank-bank-transaction-id",
    path: _91id_93M3QtmwluqJMeta?.path ?? "/bank/bank-transaction/:id()",
    children: [
  {
    name: editP1fkp6UExOMeta?.name ?? "bank-bank-transaction-id-edit",
    path: editP1fkp6UExOMeta?.path ?? "edit",
    meta: editP1fkp6UExOMeta || {},
    alias: editP1fkp6UExOMeta?.alias || [],
    redirect: editP1fkp6UExOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/bank/bank-transaction/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93M3QtmwluqJMeta || {},
    alias: _91id_93M3QtmwluqJMeta?.alias || [],
    redirect: _91id_93M3QtmwluqJMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/bank/bank-transaction/[id].vue").then(m => m.default || m)
  },
  {
    name: addtJyYaGdkYUMeta?.name ?? "bank-bank-transaction-add",
    path: addtJyYaGdkYUMeta?.path ?? "/bank/bank-transaction/add",
    meta: addtJyYaGdkYUMeta || {},
    alias: addtJyYaGdkYUMeta?.alias || [],
    redirect: addtJyYaGdkYUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/bank/bank-transaction/add.vue").then(m => m.default || m)
  },
  {
    name: importxdeYllVWaFMeta?.name ?? "bank-bank-transaction-import",
    path: importxdeYllVWaFMeta?.path ?? "/bank/bank-transaction/import",
    meta: importxdeYllVWaFMeta || {},
    alias: importxdeYllVWaFMeta?.alias || [],
    redirect: importxdeYllVWaFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/bank/bank-transaction/import.vue").then(m => m.default || m)
  },
  {
    name: indexpL48k85UdIMeta?.name ?? "bank-bank-transaction",
    path: indexpL48k85UdIMeta?.path ?? "/bank/bank-transaction",
    meta: indexpL48k85UdIMeta || {},
    alias: indexpL48k85UdIMeta?.alias || [],
    redirect: indexpL48k85UdIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/bank/bank-transaction/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_9342FnUD0ychMeta?.name ?? "bank-bank-transfer-id",
    path: _91id_9342FnUD0ychMeta?.path ?? "/bank/bank-transfer/:id()",
    children: [
  {
    name: editAYCrF7RkkfMeta?.name ?? "bank-bank-transfer-id-edit",
    path: editAYCrF7RkkfMeta?.path ?? "edit",
    meta: editAYCrF7RkkfMeta || {},
    alias: editAYCrF7RkkfMeta?.alias || [],
    redirect: editAYCrF7RkkfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/bank/bank-transfer/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_9342FnUD0ychMeta || {},
    alias: _91id_9342FnUD0ychMeta?.alias || [],
    redirect: _91id_9342FnUD0ychMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/bank/bank-transfer/[id].vue").then(m => m.default || m)
  },
  {
    name: addRUeadrAMFjMeta?.name ?? "bank-bank-transfer-add",
    path: addRUeadrAMFjMeta?.path ?? "/bank/bank-transfer/add",
    meta: addRUeadrAMFjMeta || {},
    alias: addRUeadrAMFjMeta?.alias || [],
    redirect: addRUeadrAMFjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/bank/bank-transfer/add.vue").then(m => m.default || m)
  },
  {
    name: import1AFTC4JXRHMeta?.name ?? "bank-bank-transfer-import",
    path: import1AFTC4JXRHMeta?.path ?? "/bank/bank-transfer/import",
    meta: import1AFTC4JXRHMeta || {},
    alias: import1AFTC4JXRHMeta?.alias || [],
    redirect: import1AFTC4JXRHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/bank/bank-transfer/import.vue").then(m => m.default || m)
  },
  {
    name: indexhA7JA3IkydMeta?.name ?? "bank-bank-transfer",
    path: indexhA7JA3IkydMeta?.path ?? "/bank/bank-transfer",
    meta: indexhA7JA3IkydMeta || {},
    alias: indexhA7JA3IkydMeta?.alias || [],
    redirect: indexhA7JA3IkydMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/bank/bank-transfer/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardshG8WIOwltMeta?.name ?? "business-management-dashboard",
    path: dashboardshG8WIOwltMeta?.path ?? "/business-management/dashboard",
    meta: dashboardshG8WIOwltMeta || {},
    alias: dashboardshG8WIOwltMeta?.alias || [],
    redirect: dashboardshG8WIOwltMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/business-management/dashboard.vue").then(m => m.default || m)
  },
  {
    name: checkGph7oBY2f1Meta?.name ?? "check-check",
    path: checkGph7oBY2f1Meta?.path ?? "/check/check",
    meta: checkGph7oBY2f1Meta || {},
    alias: checkGph7oBY2f1Meta?.alias || [],
    redirect: checkGph7oBY2f1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/check/check.vue").then(m => m.default || m)
  },
  {
    name: _91id_938wmAEqs1IgMeta?.name ?? "contact-contact-id",
    path: _91id_938wmAEqs1IgMeta?.path ?? "/contact/contact/:id()",
    children: [
  {
    name: editQd8kO8DyhEMeta?.name ?? "contact-contact-id-edit",
    path: editQd8kO8DyhEMeta?.path ?? "edit",
    meta: editQd8kO8DyhEMeta || {},
    alias: editQd8kO8DyhEMeta?.alias || [],
    redirect: editQd8kO8DyhEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/contact/contact/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_938wmAEqs1IgMeta || {},
    alias: _91id_938wmAEqs1IgMeta?.alias || [],
    redirect: _91id_938wmAEqs1IgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/contact/contact/[id].vue").then(m => m.default || m)
  },
  {
    name: add1mgQC1hoUpMeta?.name ?? "contact-contact-add",
    path: add1mgQC1hoUpMeta?.path ?? "/contact/contact/add",
    meta: add1mgQC1hoUpMeta || {},
    alias: add1mgQC1hoUpMeta?.alias || [],
    redirect: add1mgQC1hoUpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/contact/contact/add.vue").then(m => m.default || m)
  },
  {
    name: importhCSNkiUzPUMeta?.name ?? "contact-contact-import",
    path: importhCSNkiUzPUMeta?.path ?? "/contact/contact/import",
    meta: importhCSNkiUzPUMeta || {},
    alias: importhCSNkiUzPUMeta?.alias || [],
    redirect: importhCSNkiUzPUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/contact/contact/import.vue").then(m => m.default || m)
  },
  {
    name: indexOZ4ijZKXdxMeta?.name ?? "contact-contact",
    path: indexOZ4ijZKXdxMeta?.path ?? "/contact/contact",
    meta: indexOZ4ijZKXdxMeta || {},
    alias: indexOZ4ijZKXdxMeta?.alias || [],
    redirect: indexOZ4ijZKXdxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/contact/contact/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardjs99NhV1xpMeta?.name ?? "dashboard",
    path: dashboardjs99NhV1xpMeta?.path ?? "/dashboard",
    meta: dashboardjs99NhV1xpMeta || {},
    alias: dashboardjs99NhV1xpMeta?.alias || [],
    redirect: dashboardjs99NhV1xpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: ecommerce4NAhizbzOWMeta?.name ?? "ecommerce",
    path: ecommerce4NAhizbzOWMeta?.path ?? "/ecommerce",
    meta: ecommerce4NAhizbzOWMeta || {},
    alias: ecommerce4NAhizbzOWMeta?.alias || [],
    redirect: ecommerce4NAhizbzOWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/ecommerce.vue").then(m => m.default || m)
  },
  {
    name: _91id_93b3WXtnvQNAMeta?.name ?? "expense-expense-claim-id",
    path: _91id_93b3WXtnvQNAMeta?.path ?? "/expense/expense-claim/:id()",
    children: [
  {
    name: editvhlIr7YC63Meta?.name ?? "expense-expense-claim-id-edit",
    path: editvhlIr7YC63Meta?.path ?? "edit",
    meta: editvhlIr7YC63Meta || {},
    alias: editvhlIr7YC63Meta?.alias || [],
    redirect: editvhlIr7YC63Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/expense/expense-claim/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93b3WXtnvQNAMeta || {},
    alias: _91id_93b3WXtnvQNAMeta?.alias || [],
    redirect: _91id_93b3WXtnvQNAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/expense/expense-claim/[id].vue").then(m => m.default || m)
  },
  {
    name: addj8a7BU2Ie4Meta?.name ?? "expense-expense-claim-add",
    path: addj8a7BU2Ie4Meta?.path ?? "/expense/expense-claim/add",
    meta: addj8a7BU2Ie4Meta || {},
    alias: addj8a7BU2Ie4Meta?.alias || [],
    redirect: addj8a7BU2Ie4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/expense/expense-claim/add.vue").then(m => m.default || m)
  },
  {
    name: import6Ev6AjkGfpMeta?.name ?? "expense-expense-claim-import",
    path: import6Ev6AjkGfpMeta?.path ?? "/expense/expense-claim/import",
    meta: import6Ev6AjkGfpMeta || {},
    alias: import6Ev6AjkGfpMeta?.alias || [],
    redirect: import6Ev6AjkGfpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/expense/expense-claim/import.vue").then(m => m.default || m)
  },
  {
    name: index6Y5kcwWiKyMeta?.name ?? "expense-expense-claim",
    path: index6Y5kcwWiKyMeta?.path ?? "/expense/expense-claim",
    meta: index6Y5kcwWiKyMeta || {},
    alias: index6Y5kcwWiKyMeta?.alias || [],
    redirect: index6Y5kcwWiKyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/expense/expense-claim/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_939FRaQnOKWCMeta?.name ?? "expense-expense-id",
    path: _91id_939FRaQnOKWCMeta?.path ?? "/expense/expense/:id()",
    children: [
  {
    name: edit93ulmsO6EbMeta?.name ?? "expense-expense-id-edit",
    path: edit93ulmsO6EbMeta?.path ?? "edit",
    meta: edit93ulmsO6EbMeta || {},
    alias: edit93ulmsO6EbMeta?.alias || [],
    redirect: edit93ulmsO6EbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/expense/expense/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_939FRaQnOKWCMeta || {},
    alias: _91id_939FRaQnOKWCMeta?.alias || [],
    redirect: _91id_939FRaQnOKWCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/expense/expense/[id].vue").then(m => m.default || m)
  },
  {
    name: addDhfBuB7NzUMeta?.name ?? "expense-expense-add",
    path: addDhfBuB7NzUMeta?.path ?? "/expense/expense/add",
    meta: addDhfBuB7NzUMeta || {},
    alias: addDhfBuB7NzUMeta?.alias || [],
    redirect: addDhfBuB7NzUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/expense/expense/add.vue").then(m => m.default || m)
  },
  {
    name: importkz6bBManC4Meta?.name ?? "expense-expense-import",
    path: importkz6bBManC4Meta?.path ?? "/expense/expense/import",
    meta: importkz6bBManC4Meta || {},
    alias: importkz6bBManC4Meta?.alias || [],
    redirect: importkz6bBManC4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/expense/expense/import.vue").then(m => m.default || m)
  },
  {
    name: indexBe8hqZJvdqMeta?.name ?? "expense-expense",
    path: indexBe8hqZJvdqMeta?.path ?? "/expense/expense",
    meta: indexBe8hqZJvdqMeta || {},
    alias: indexBe8hqZJvdqMeta?.alias || [],
    redirect: indexBe8hqZJvdqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/expense/expense/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93v9SjfMZ9zDMeta?.name ?? "expense-recurring-expense-id",
    path: _91id_93v9SjfMZ9zDMeta?.path ?? "/expense/recurring-expense/:id()",
    children: [
  {
    name: editwfPoz5mS93Meta?.name ?? "expense-recurring-expense-id-edit",
    path: editwfPoz5mS93Meta?.path ?? "edit",
    meta: editwfPoz5mS93Meta || {},
    alias: editwfPoz5mS93Meta?.alias || [],
    redirect: editwfPoz5mS93Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/expense/recurring-expense/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93v9SjfMZ9zDMeta || {},
    alias: _91id_93v9SjfMZ9zDMeta?.alias || [],
    redirect: _91id_93v9SjfMZ9zDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/expense/recurring-expense/[id].vue").then(m => m.default || m)
  },
  {
    name: adddtmbhr42KFMeta?.name ?? "expense-recurring-expense-add",
    path: adddtmbhr42KFMeta?.path ?? "/expense/recurring-expense/add",
    meta: adddtmbhr42KFMeta || {},
    alias: adddtmbhr42KFMeta?.alias || [],
    redirect: adddtmbhr42KFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/expense/recurring-expense/add.vue").then(m => m.default || m)
  },
  {
    name: importjMIQ0afDKcMeta?.name ?? "expense-recurring-expense-import",
    path: importjMIQ0afDKcMeta?.path ?? "/expense/recurring-expense/import",
    meta: importjMIQ0afDKcMeta || {},
    alias: importjMIQ0afDKcMeta?.alias || [],
    redirect: importjMIQ0afDKcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/expense/recurring-expense/import.vue").then(m => m.default || m)
  },
  {
    name: indexuEW1AKlvvZMeta?.name ?? "expense-recurring-expense",
    path: indexuEW1AKlvvZMeta?.path ?? "/expense/recurring-expense",
    meta: indexuEW1AKlvvZMeta || {},
    alias: indexuEW1AKlvvZMeta?.alias || [],
    redirect: indexuEW1AKlvvZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/expense/recurring-expense/index.vue").then(m => m.default || m)
  },
  {
    name: financial_45modelfLfIXIJ1VEMeta?.name ?? "financial-model",
    path: financial_45modelfLfIXIJ1VEMeta?.path ?? "/financial-model",
    meta: financial_45modelfLfIXIJ1VEMeta || {},
    alias: financial_45modelfLfIXIJ1VEMeta?.alias || [],
    redirect: financial_45modelfLfIXIJ1VEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/financial-model.vue").then(m => m.default || m)
  },
  {
    name: indexJX2dp37VJQMeta?.name ?? "index",
    path: indexJX2dp37VJQMeta?.path ?? "/",
    meta: indexJX2dp37VJQMeta || {},
    alias: indexJX2dp37VJQMeta?.alias || [],
    redirect: indexJX2dp37VJQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93tlSs0aTUbWMeta?.name ?? "item-inventory-adjustment-id",
    path: _91id_93tlSs0aTUbWMeta?.path ?? "/item/inventory-adjustment/:id()",
    children: [
  {
    name: edit5HHSqTPvrPMeta?.name ?? "item-inventory-adjustment-id-edit",
    path: edit5HHSqTPvrPMeta?.path ?? "edit",
    meta: edit5HHSqTPvrPMeta || {},
    alias: edit5HHSqTPvrPMeta?.alias || [],
    redirect: edit5HHSqTPvrPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/item/inventory-adjustment/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93tlSs0aTUbWMeta || {},
    alias: _91id_93tlSs0aTUbWMeta?.alias || [],
    redirect: _91id_93tlSs0aTUbWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/item/inventory-adjustment/[id].vue").then(m => m.default || m)
  },
  {
    name: addI1yK510vmXMeta?.name ?? "item-inventory-adjustment-add",
    path: addI1yK510vmXMeta?.path ?? "/item/inventory-adjustment/add",
    meta: addI1yK510vmXMeta || {},
    alias: addI1yK510vmXMeta?.alias || [],
    redirect: addI1yK510vmXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/item/inventory-adjustment/add.vue").then(m => m.default || m)
  },
  {
    name: import9Mu3P6ZjNkMeta?.name ?? "item-inventory-adjustment-import",
    path: import9Mu3P6ZjNkMeta?.path ?? "/item/inventory-adjustment/import",
    meta: import9Mu3P6ZjNkMeta || {},
    alias: import9Mu3P6ZjNkMeta?.alias || [],
    redirect: import9Mu3P6ZjNkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/item/inventory-adjustment/import.vue").then(m => m.default || m)
  },
  {
    name: indexkvNd5p2iCYMeta?.name ?? "item-inventory-adjustment",
    path: indexkvNd5p2iCYMeta?.path ?? "/item/inventory-adjustment",
    meta: indexkvNd5p2iCYMeta || {},
    alias: indexkvNd5p2iCYMeta?.alias || [],
    redirect: indexkvNd5p2iCYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/item/inventory-adjustment/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93atXMvYV3AYMeta?.name ?? "item-item-id",
    path: _91id_93atXMvYV3AYMeta?.path ?? "/item/item/:id()",
    children: [
  {
    name: editDExkjKB2z3Meta?.name ?? "item-item-id-edit",
    path: editDExkjKB2z3Meta?.path ?? "edit",
    meta: editDExkjKB2z3Meta || {},
    alias: editDExkjKB2z3Meta?.alias || [],
    redirect: editDExkjKB2z3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/item/item/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93atXMvYV3AYMeta || {},
    alias: _91id_93atXMvYV3AYMeta?.alias || [],
    redirect: _91id_93atXMvYV3AYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/item/item/[id].vue").then(m => m.default || m)
  },
  {
    name: addKJyoTdfXByMeta?.name ?? "item-item-add",
    path: addKJyoTdfXByMeta?.path ?? "/item/item/add",
    meta: addKJyoTdfXByMeta || {},
    alias: addKJyoTdfXByMeta?.alias || [],
    redirect: addKJyoTdfXByMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/item/item/add.vue").then(m => m.default || m)
  },
  {
    name: importhy9JFZknYUMeta?.name ?? "item-item-import",
    path: importhy9JFZknYUMeta?.path ?? "/item/item/import",
    meta: importhy9JFZknYUMeta || {},
    alias: importhy9JFZknYUMeta?.alias || [],
    redirect: importhy9JFZknYUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/item/item/import.vue").then(m => m.default || m)
  },
  {
    name: indexIsME4Nk4zjMeta?.name ?? "item-item",
    path: indexIsME4Nk4zjMeta?.path ?? "/item/item",
    meta: indexIsME4Nk4zjMeta || {},
    alias: indexIsME4Nk4zjMeta?.alias || [],
    redirect: indexIsME4Nk4zjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/item/item/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dE7BY4sefyMeta?.name ?? "item-price-list-id",
    path: _91id_93dE7BY4sefyMeta?.path ?? "/item/price-list/:id()",
    children: [
  {
    name: editWJ7DudvVdyMeta?.name ?? "item-price-list-id-edit",
    path: editWJ7DudvVdyMeta?.path ?? "edit",
    meta: editWJ7DudvVdyMeta || {},
    alias: editWJ7DudvVdyMeta?.alias || [],
    redirect: editWJ7DudvVdyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/item/price-list/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93dE7BY4sefyMeta || {},
    alias: _91id_93dE7BY4sefyMeta?.alias || [],
    redirect: _91id_93dE7BY4sefyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/item/price-list/[id].vue").then(m => m.default || m)
  },
  {
    name: addWOP1mJu9qfMeta?.name ?? "item-price-list-add",
    path: addWOP1mJu9qfMeta?.path ?? "/item/price-list/add",
    meta: addWOP1mJu9qfMeta || {},
    alias: addWOP1mJu9qfMeta?.alias || [],
    redirect: addWOP1mJu9qfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/item/price-list/add.vue").then(m => m.default || m)
  },
  {
    name: importpKnfzieQnlMeta?.name ?? "item-price-list-import",
    path: importpKnfzieQnlMeta?.path ?? "/item/price-list/import",
    meta: importpKnfzieQnlMeta || {},
    alias: importpKnfzieQnlMeta?.alias || [],
    redirect: importpKnfzieQnlMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/item/price-list/import.vue").then(m => m.default || m)
  },
  {
    name: indexaOixNp1zAOMeta?.name ?? "item-price-list",
    path: indexaOixNp1zAOMeta?.path ?? "/item/price-list",
    meta: indexaOixNp1zAOMeta || {},
    alias: indexaOixNp1zAOMeta?.alias || [],
    redirect: indexaOixNp1zAOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/item/price-list/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93FlvSVYCHk4Meta?.name ?? "journal-journal-id",
    path: _91id_93FlvSVYCHk4Meta?.path ?? "/journal/journal/:id()",
    children: [
  {
    name: edit7UJtNCPqKnMeta?.name ?? "journal-journal-id-edit",
    path: edit7UJtNCPqKnMeta?.path ?? "edit",
    meta: edit7UJtNCPqKnMeta || {},
    alias: edit7UJtNCPqKnMeta?.alias || [],
    redirect: edit7UJtNCPqKnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/journal/journal/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93FlvSVYCHk4Meta || {},
    alias: _91id_93FlvSVYCHk4Meta?.alias || [],
    redirect: _91id_93FlvSVYCHk4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/journal/journal/[id].vue").then(m => m.default || m)
  },
  {
    name: addDw4WCrqpsvMeta?.name ?? "journal-journal-add",
    path: addDw4WCrqpsvMeta?.path ?? "/journal/journal/add",
    meta: addDw4WCrqpsvMeta || {},
    alias: addDw4WCrqpsvMeta?.alias || [],
    redirect: addDw4WCrqpsvMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/journal/journal/add.vue").then(m => m.default || m)
  },
  {
    name: importYzveCQH0WiMeta?.name ?? "journal-journal-import",
    path: importYzveCQH0WiMeta?.path ?? "/journal/journal/import",
    meta: importYzveCQH0WiMeta || {},
    alias: importYzveCQH0WiMeta?.alias || [],
    redirect: importYzveCQH0WiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/journal/journal/import.vue").then(m => m.default || m)
  },
  {
    name: indexHyP1Uj12jOMeta?.name ?? "journal-journal",
    path: indexHyP1Uj12jOMeta?.path ?? "/journal/journal",
    meta: indexHyP1Uj12jOMeta || {},
    alias: indexHyP1Uj12jOMeta?.alias || [],
    redirect: indexHyP1Uj12jOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/journal/journal/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zVEXVyv8OfMeta?.name ?? "journal-recurring-journal-id",
    path: _91id_93zVEXVyv8OfMeta?.path ?? "/journal/recurring-journal/:id()",
    children: [
  {
    name: edit5GHaDzr7I7Meta?.name ?? "journal-recurring-journal-id-edit",
    path: edit5GHaDzr7I7Meta?.path ?? "edit",
    meta: edit5GHaDzr7I7Meta || {},
    alias: edit5GHaDzr7I7Meta?.alias || [],
    redirect: edit5GHaDzr7I7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/journal/recurring-journal/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93zVEXVyv8OfMeta || {},
    alias: _91id_93zVEXVyv8OfMeta?.alias || [],
    redirect: _91id_93zVEXVyv8OfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/journal/recurring-journal/[id].vue").then(m => m.default || m)
  },
  {
    name: addkwOvnAQk4bMeta?.name ?? "journal-recurring-journal-add",
    path: addkwOvnAQk4bMeta?.path ?? "/journal/recurring-journal/add",
    meta: addkwOvnAQk4bMeta || {},
    alias: addkwOvnAQk4bMeta?.alias || [],
    redirect: addkwOvnAQk4bMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/journal/recurring-journal/add.vue").then(m => m.default || m)
  },
  {
    name: importOYAQTW7aJdMeta?.name ?? "journal-recurring-journal-import",
    path: importOYAQTW7aJdMeta?.path ?? "/journal/recurring-journal/import",
    meta: importOYAQTW7aJdMeta || {},
    alias: importOYAQTW7aJdMeta?.alias || [],
    redirect: importOYAQTW7aJdMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/journal/recurring-journal/import.vue").then(m => m.default || m)
  },
  {
    name: indexVem4ywWA2rMeta?.name ?? "journal-recurring-journal",
    path: indexVem4ywWA2rMeta?.path ?? "/journal/recurring-journal",
    meta: indexVem4ywWA2rMeta || {},
    alias: indexVem4ywWA2rMeta?.alias || [],
    redirect: indexVem4ywWA2rMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/journal/recurring-journal/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardaGE68aD5Q9Meta?.name ?? "management-dashboard",
    path: dashboardaGE68aD5Q9Meta?.path ?? "/management/dashboard",
    meta: dashboardaGE68aD5Q9Meta || {},
    alias: dashboardaGE68aD5Q9Meta?.alias || [],
    redirect: dashboardaGE68aD5Q9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/management/dashboard.vue").then(m => m.default || m)
  },
  {
    name: marketplaceaMMBvkinluMeta?.name ?? "marketplace",
    path: marketplaceaMMBvkinluMeta?.path ?? "/marketplace",
    meta: marketplaceaMMBvkinluMeta || {},
    alias: marketplaceaMMBvkinluMeta?.alias || [],
    redirect: marketplaceaMMBvkinluMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/marketplace.vue").then(m => m.default || m)
  },
  {
    name: _91id_93me50cOnMaVMeta?.name ?? "payment-business-payment-id",
    path: _91id_93me50cOnMaVMeta?.path ?? "/payment/business-payment/:id()",
    children: [
  {
    name: editx154LuipCXMeta?.name ?? "payment-business-payment-id-edit",
    path: editx154LuipCXMeta?.path ?? "edit",
    meta: editx154LuipCXMeta || {},
    alias: editx154LuipCXMeta?.alias || [],
    redirect: editx154LuipCXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/business-payment/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93me50cOnMaVMeta || {},
    alias: _91id_93me50cOnMaVMeta?.alias || [],
    redirect: _91id_93me50cOnMaVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/business-payment/[id].vue").then(m => m.default || m)
  },
  {
    name: addFq1tdvU5WmMeta?.name ?? "payment-business-payment-add",
    path: addFq1tdvU5WmMeta?.path ?? "/payment/business-payment/add",
    meta: addFq1tdvU5WmMeta || {},
    alias: addFq1tdvU5WmMeta?.alias || [],
    redirect: addFq1tdvU5WmMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/business-payment/add.vue").then(m => m.default || m)
  },
  {
    name: importDJffI50cLqMeta?.name ?? "payment-business-payment-import",
    path: importDJffI50cLqMeta?.path ?? "/payment/business-payment/import",
    meta: importDJffI50cLqMeta || {},
    alias: importDJffI50cLqMeta?.alias || [],
    redirect: importDJffI50cLqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/business-payment/import.vue").then(m => m.default || m)
  },
  {
    name: index8rcLVMEJQvMeta?.name ?? "payment-business-payment",
    path: index8rcLVMEJQvMeta?.path ?? "/payment/business-payment",
    meta: index8rcLVMEJQvMeta || {},
    alias: index8rcLVMEJQvMeta?.alias || [],
    redirect: index8rcLVMEJQvMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/business-payment/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_934HJgAR8tiEMeta?.name ?? "payment-customer-payment-id",
    path: _91id_934HJgAR8tiEMeta?.path ?? "/payment/customer-payment/:id()",
    children: [
  {
    name: editHB2Jh6xOnMMeta?.name ?? "payment-customer-payment-id-edit",
    path: editHB2Jh6xOnMMeta?.path ?? "edit",
    meta: editHB2Jh6xOnMMeta || {},
    alias: editHB2Jh6xOnMMeta?.alias || [],
    redirect: editHB2Jh6xOnMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/customer-payment/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_934HJgAR8tiEMeta || {},
    alias: _91id_934HJgAR8tiEMeta?.alias || [],
    redirect: _91id_934HJgAR8tiEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/customer-payment/[id].vue").then(m => m.default || m)
  },
  {
    name: addJJzwiMufr1Meta?.name ?? "payment-customer-payment-add",
    path: addJJzwiMufr1Meta?.path ?? "/payment/customer-payment/add",
    meta: addJJzwiMufr1Meta || {},
    alias: addJJzwiMufr1Meta?.alias || [],
    redirect: addJJzwiMufr1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/customer-payment/add.vue").then(m => m.default || m)
  },
  {
    name: import5RNoNW7CF9Meta?.name ?? "payment-customer-payment-import",
    path: import5RNoNW7CF9Meta?.path ?? "/payment/customer-payment/import",
    meta: import5RNoNW7CF9Meta || {},
    alias: import5RNoNW7CF9Meta?.alias || [],
    redirect: import5RNoNW7CF9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/customer-payment/import.vue").then(m => m.default || m)
  },
  {
    name: indexk80MFuns6fMeta?.name ?? "payment-customer-payment",
    path: indexk80MFuns6fMeta?.path ?? "/payment/customer-payment",
    meta: indexk80MFuns6fMeta || {},
    alias: indexk80MFuns6fMeta?.alias || [],
    redirect: indexk80MFuns6fMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/customer-payment/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93tqeiAR4vrjMeta?.name ?? "payment-over-payment-id",
    path: _91id_93tqeiAR4vrjMeta?.path ?? "/payment/over-payment/:id()",
    children: [
  {
    name: edit3OoFtDlB5DMeta?.name ?? "payment-over-payment-id-edit",
    path: edit3OoFtDlB5DMeta?.path ?? "edit",
    meta: edit3OoFtDlB5DMeta || {},
    alias: edit3OoFtDlB5DMeta?.alias || [],
    redirect: edit3OoFtDlB5DMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/over-payment/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93tqeiAR4vrjMeta || {},
    alias: _91id_93tqeiAR4vrjMeta?.alias || [],
    redirect: _91id_93tqeiAR4vrjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/over-payment/[id].vue").then(m => m.default || m)
  },
  {
    name: addgfujAoITyPMeta?.name ?? "payment-over-payment-add",
    path: addgfujAoITyPMeta?.path ?? "/payment/over-payment/add",
    meta: addgfujAoITyPMeta || {},
    alias: addgfujAoITyPMeta?.alias || [],
    redirect: addgfujAoITyPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/over-payment/add.vue").then(m => m.default || m)
  },
  {
    name: importbKPJ89lrdOMeta?.name ?? "payment-over-payment-import",
    path: importbKPJ89lrdOMeta?.path ?? "/payment/over-payment/import",
    meta: importbKPJ89lrdOMeta || {},
    alias: importbKPJ89lrdOMeta?.alias || [],
    redirect: importbKPJ89lrdOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/over-payment/import.vue").then(m => m.default || m)
  },
  {
    name: indexx7lS45lET6Meta?.name ?? "payment-over-payment",
    path: indexx7lS45lET6Meta?.path ?? "/payment/over-payment",
    meta: indexx7lS45lET6Meta || {},
    alias: indexx7lS45lET6Meta?.alias || [],
    redirect: indexx7lS45lET6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/over-payment/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QcrRBH1a7pMeta?.name ?? "payment-payment-service-id",
    path: _91id_93QcrRBH1a7pMeta?.path ?? "/payment/payment-service/:id()",
    children: [
  {
    name: editAANevZqv8ZMeta?.name ?? "payment-payment-service-id-edit",
    path: editAANevZqv8ZMeta?.path ?? "edit",
    meta: editAANevZqv8ZMeta || {},
    alias: editAANevZqv8ZMeta?.alias || [],
    redirect: editAANevZqv8ZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/payment-service/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93QcrRBH1a7pMeta || {},
    alias: _91id_93QcrRBH1a7pMeta?.alias || [],
    redirect: _91id_93QcrRBH1a7pMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/payment-service/[id].vue").then(m => m.default || m)
  },
  {
    name: addXDDmU2GDVLMeta?.name ?? "payment-payment-service-add",
    path: addXDDmU2GDVLMeta?.path ?? "/payment/payment-service/add",
    meta: addXDDmU2GDVLMeta || {},
    alias: addXDDmU2GDVLMeta?.alias || [],
    redirect: addXDDmU2GDVLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/payment-service/add.vue").then(m => m.default || m)
  },
  {
    name: importsZCYXbNs4DMeta?.name ?? "payment-payment-service-import",
    path: importsZCYXbNs4DMeta?.path ?? "/payment/payment-service/import",
    meta: importsZCYXbNs4DMeta || {},
    alias: importsZCYXbNs4DMeta?.alias || [],
    redirect: importsZCYXbNs4DMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/payment-service/import.vue").then(m => m.default || m)
  },
  {
    name: indexOPlBEcVsPnMeta?.name ?? "payment-payment-service",
    path: indexOPlBEcVsPnMeta?.path ?? "/payment/payment-service",
    meta: indexOPlBEcVsPnMeta || {},
    alias: indexOPlBEcVsPnMeta?.alias || [],
    redirect: indexOPlBEcVsPnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/payment-service/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ueGuutu9v7Meta?.name ?? "payment-pre-payment-id",
    path: _91id_93ueGuutu9v7Meta?.path ?? "/payment/pre-payment/:id()",
    children: [
  {
    name: editM5IJuP4PQXMeta?.name ?? "payment-pre-payment-id-edit",
    path: editM5IJuP4PQXMeta?.path ?? "edit",
    meta: editM5IJuP4PQXMeta || {},
    alias: editM5IJuP4PQXMeta?.alias || [],
    redirect: editM5IJuP4PQXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/pre-payment/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93ueGuutu9v7Meta || {},
    alias: _91id_93ueGuutu9v7Meta?.alias || [],
    redirect: _91id_93ueGuutu9v7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/pre-payment/[id].vue").then(m => m.default || m)
  },
  {
    name: addZ43pM3Vpf1Meta?.name ?? "payment-pre-payment-add",
    path: addZ43pM3Vpf1Meta?.path ?? "/payment/pre-payment/add",
    meta: addZ43pM3Vpf1Meta || {},
    alias: addZ43pM3Vpf1Meta?.alias || [],
    redirect: addZ43pM3Vpf1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/pre-payment/add.vue").then(m => m.default || m)
  },
  {
    name: importPeE8y8SRDRMeta?.name ?? "payment-pre-payment-import",
    path: importPeE8y8SRDRMeta?.path ?? "/payment/pre-payment/import",
    meta: importPeE8y8SRDRMeta || {},
    alias: importPeE8y8SRDRMeta?.alias || [],
    redirect: importPeE8y8SRDRMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/pre-payment/import.vue").then(m => m.default || m)
  },
  {
    name: indexumAGFkVCQeMeta?.name ?? "payment-pre-payment",
    path: indexumAGFkVCQeMeta?.path ?? "/payment/pre-payment",
    meta: indexumAGFkVCQeMeta || {},
    alias: indexumAGFkVCQeMeta?.alias || [],
    redirect: indexumAGFkVCQeMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/payment/pre-payment/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93T4xtKNpOwPMeta?.name ?? "purchase-bill-id",
    path: _91id_93T4xtKNpOwPMeta?.path ?? "/purchase/bill/:id()",
    children: [
  {
    name: editD2cYi0RvNqMeta?.name ?? "purchase-bill-id-edit",
    path: editD2cYi0RvNqMeta?.path ?? "edit",
    meta: editD2cYi0RvNqMeta || {},
    alias: editD2cYi0RvNqMeta?.alias || [],
    redirect: editD2cYi0RvNqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/purchase/bill/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93T4xtKNpOwPMeta || {},
    alias: _91id_93T4xtKNpOwPMeta?.alias || [],
    redirect: _91id_93T4xtKNpOwPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/purchase/bill/[id].vue").then(m => m.default || m)
  },
  {
    name: addhHArN1BsK1Meta?.name ?? "purchase-bill-add",
    path: addhHArN1BsK1Meta?.path ?? "/purchase/bill/add",
    meta: addhHArN1BsK1Meta || {},
    alias: addhHArN1BsK1Meta?.alias || [],
    redirect: addhHArN1BsK1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/purchase/bill/add.vue").then(m => m.default || m)
  },
  {
    name: import0cms63hRIcMeta?.name ?? "purchase-bill-import",
    path: import0cms63hRIcMeta?.path ?? "/purchase/bill/import",
    meta: import0cms63hRIcMeta || {},
    alias: import0cms63hRIcMeta?.alias || [],
    redirect: import0cms63hRIcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/purchase/bill/import.vue").then(m => m.default || m)
  },
  {
    name: indexncsyrSf8FYMeta?.name ?? "purchase-bill",
    path: indexncsyrSf8FYMeta?.path ?? "/purchase/bill",
    meta: indexncsyrSf8FYMeta || {},
    alias: indexncsyrSf8FYMeta?.alias || [],
    redirect: indexncsyrSf8FYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/purchase/bill/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cKZ0zmEHztMeta?.name ?? "purchase-recurring-bill-id",
    path: _91id_93cKZ0zmEHztMeta?.path ?? "/purchase/recurring-bill/:id()",
    children: [
  {
    name: editrh5GLffeCXMeta?.name ?? "purchase-recurring-bill-id-edit",
    path: editrh5GLffeCXMeta?.path ?? "edit",
    meta: editrh5GLffeCXMeta || {},
    alias: editrh5GLffeCXMeta?.alias || [],
    redirect: editrh5GLffeCXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/purchase/recurring-bill/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93cKZ0zmEHztMeta || {},
    alias: _91id_93cKZ0zmEHztMeta?.alias || [],
    redirect: _91id_93cKZ0zmEHztMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/purchase/recurring-bill/[id].vue").then(m => m.default || m)
  },
  {
    name: addIKR7YJ1ntzMeta?.name ?? "purchase-recurring-bill-add",
    path: addIKR7YJ1ntzMeta?.path ?? "/purchase/recurring-bill/add",
    meta: addIKR7YJ1ntzMeta || {},
    alias: addIKR7YJ1ntzMeta?.alias || [],
    redirect: addIKR7YJ1ntzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/purchase/recurring-bill/add.vue").then(m => m.default || m)
  },
  {
    name: import6P3aFiEEtPMeta?.name ?? "purchase-recurring-bill-import",
    path: import6P3aFiEEtPMeta?.path ?? "/purchase/recurring-bill/import",
    meta: import6P3aFiEEtPMeta || {},
    alias: import6P3aFiEEtPMeta?.alias || [],
    redirect: import6P3aFiEEtPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/purchase/recurring-bill/import.vue").then(m => m.default || m)
  },
  {
    name: indexe9YRAYlOaDMeta?.name ?? "purchase-recurring-bill",
    path: indexe9YRAYlOaDMeta?.path ?? "/purchase/recurring-bill",
    meta: indexe9YRAYlOaDMeta || {},
    alias: indexe9YRAYlOaDMeta?.alias || [],
    redirect: indexe9YRAYlOaDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/purchase/recurring-bill/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93DZ9TKRcP4FMeta?.name ?? "purchase-vendor-credit-id",
    path: _91id_93DZ9TKRcP4FMeta?.path ?? "/purchase/vendor-credit/:id()",
    children: [
  {
    name: editQzULHtRzPyMeta?.name ?? "purchase-vendor-credit-id-edit",
    path: editQzULHtRzPyMeta?.path ?? "edit",
    meta: editQzULHtRzPyMeta || {},
    alias: editQzULHtRzPyMeta?.alias || [],
    redirect: editQzULHtRzPyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/purchase/vendor-credit/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93DZ9TKRcP4FMeta || {},
    alias: _91id_93DZ9TKRcP4FMeta?.alias || [],
    redirect: _91id_93DZ9TKRcP4FMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/purchase/vendor-credit/[id].vue").then(m => m.default || m)
  },
  {
    name: addUv82UZTumJMeta?.name ?? "purchase-vendor-credit-add",
    path: addUv82UZTumJMeta?.path ?? "/purchase/vendor-credit/add",
    meta: addUv82UZTumJMeta || {},
    alias: addUv82UZTumJMeta?.alias || [],
    redirect: addUv82UZTumJMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/purchase/vendor-credit/add.vue").then(m => m.default || m)
  },
  {
    name: importKog4dFafzzMeta?.name ?? "purchase-vendor-credit-import",
    path: importKog4dFafzzMeta?.path ?? "/purchase/vendor-credit/import",
    meta: importKog4dFafzzMeta || {},
    alias: importKog4dFafzzMeta?.alias || [],
    redirect: importKog4dFafzzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/purchase/vendor-credit/import.vue").then(m => m.default || m)
  },
  {
    name: indexvwH1xF4n8SMeta?.name ?? "purchase-vendor-credit",
    path: indexvwH1xF4n8SMeta?.path ?? "/purchase/vendor-credit",
    meta: indexvwH1xF4n8SMeta || {},
    alias: indexvwH1xF4n8SMeta?.alias || [],
    redirect: indexvwH1xF4n8SMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/purchase/vendor-credit/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_939V4ARVBInLMeta?.name ?? "sale-credit-note-id",
    path: _91id_939V4ARVBInLMeta?.path ?? "/sale/credit-note/:id()",
    children: [
  {
    name: editM0HYoI7vHQMeta?.name ?? "sale-credit-note-id-edit",
    path: editM0HYoI7vHQMeta?.path ?? "edit",
    meta: editM0HYoI7vHQMeta || {},
    alias: editM0HYoI7vHQMeta?.alias || [],
    redirect: editM0HYoI7vHQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/credit-note/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_939V4ARVBInLMeta || {},
    alias: _91id_939V4ARVBInLMeta?.alias || [],
    redirect: _91id_939V4ARVBInLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/credit-note/[id].vue").then(m => m.default || m)
  },
  {
    name: addpQeQJf1ntnMeta?.name ?? "sale-credit-note-add",
    path: addpQeQJf1ntnMeta?.path ?? "/sale/credit-note/add",
    meta: addpQeQJf1ntnMeta || {},
    alias: addpQeQJf1ntnMeta?.alias || [],
    redirect: addpQeQJf1ntnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/credit-note/add.vue").then(m => m.default || m)
  },
  {
    name: import3h5pjpex2AMeta?.name ?? "sale-credit-note-import",
    path: import3h5pjpex2AMeta?.path ?? "/sale/credit-note/import",
    meta: import3h5pjpex2AMeta || {},
    alias: import3h5pjpex2AMeta?.alias || [],
    redirect: import3h5pjpex2AMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/credit-note/import.vue").then(m => m.default || m)
  },
  {
    name: indexAjIil7OPfUMeta?.name ?? "sale-credit-note",
    path: indexAjIil7OPfUMeta?.path ?? "/sale/credit-note",
    meta: indexAjIil7OPfUMeta || {},
    alias: indexAjIil7OPfUMeta?.alias || [],
    redirect: indexAjIil7OPfUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/credit-note/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qlrPfak4xSMeta?.name ?? "sale-estimate-id",
    path: _91id_93qlrPfak4xSMeta?.path ?? "/sale/estimate/:id()",
    children: [
  {
    name: edit7ZoZJBr2lEMeta?.name ?? "sale-estimate-id-edit",
    path: edit7ZoZJBr2lEMeta?.path ?? "edit",
    meta: edit7ZoZJBr2lEMeta || {},
    alias: edit7ZoZJBr2lEMeta?.alias || [],
    redirect: edit7ZoZJBr2lEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/estimate/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93qlrPfak4xSMeta || {},
    alias: _91id_93qlrPfak4xSMeta?.alias || [],
    redirect: _91id_93qlrPfak4xSMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/estimate/[id].vue").then(m => m.default || m)
  },
  {
    name: addRarV2EqdIrMeta?.name ?? "sale-estimate-add",
    path: addRarV2EqdIrMeta?.path ?? "/sale/estimate/add",
    meta: addRarV2EqdIrMeta || {},
    alias: addRarV2EqdIrMeta?.alias || [],
    redirect: addRarV2EqdIrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/estimate/add.vue").then(m => m.default || m)
  },
  {
    name: importtPNRgnXtikMeta?.name ?? "sale-estimate-import",
    path: importtPNRgnXtikMeta?.path ?? "/sale/estimate/import",
    meta: importtPNRgnXtikMeta || {},
    alias: importtPNRgnXtikMeta?.alias || [],
    redirect: importtPNRgnXtikMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/estimate/import.vue").then(m => m.default || m)
  },
  {
    name: indexsT9TIwtnMYMeta?.name ?? "sale-estimate",
    path: indexsT9TIwtnMYMeta?.path ?? "/sale/estimate",
    meta: indexsT9TIwtnMYMeta || {},
    alias: indexsT9TIwtnMYMeta?.alias || [],
    redirect: indexsT9TIwtnMYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/estimate/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93YPrcBC3y3vMeta?.name ?? "sale-invoice-id",
    path: _91id_93YPrcBC3y3vMeta?.path ?? "/sale/invoice/:id()",
    children: [
  {
    name: editCP950n5oFdMeta?.name ?? "sale-invoice-id-edit",
    path: editCP950n5oFdMeta?.path ?? "edit",
    meta: editCP950n5oFdMeta || {},
    alias: editCP950n5oFdMeta?.alias || [],
    redirect: editCP950n5oFdMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/invoice/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93YPrcBC3y3vMeta || {},
    alias: _91id_93YPrcBC3y3vMeta?.alias || [],
    redirect: _91id_93YPrcBC3y3vMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/invoice/[id].vue").then(m => m.default || m)
  },
  {
    name: addqpIeJgYHZtMeta?.name ?? "sale-invoice-add",
    path: addqpIeJgYHZtMeta?.path ?? "/sale/invoice/add",
    meta: addqpIeJgYHZtMeta || {},
    alias: addqpIeJgYHZtMeta?.alias || [],
    redirect: addqpIeJgYHZtMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/invoice/add.vue").then(m => m.default || m)
  },
  {
    name: importj6N7Q9LtIiMeta?.name ?? "sale-invoice-import",
    path: importj6N7Q9LtIiMeta?.path ?? "/sale/invoice/import",
    meta: importj6N7Q9LtIiMeta || {},
    alias: importj6N7Q9LtIiMeta?.alias || [],
    redirect: importj6N7Q9LtIiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/invoice/import.vue").then(m => m.default || m)
  },
  {
    name: indexrh5eGOa4oXMeta?.name ?? "sale-invoice",
    path: indexrh5eGOa4oXMeta?.path ?? "/sale/invoice",
    meta: indexrh5eGOa4oXMeta || {},
    alias: indexrh5eGOa4oXMeta?.alias || [],
    redirect: indexrh5eGOa4oXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/invoice/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93eMOkg5ocmfMeta?.name ?? "sale-order-id",
    path: _91id_93eMOkg5ocmfMeta?.path ?? "/sale/order/:id()",
    children: [
  {
    name: editIxKdK4ebTgMeta?.name ?? "sale-order-id-edit",
    path: editIxKdK4ebTgMeta?.path ?? "edit",
    meta: editIxKdK4ebTgMeta || {},
    alias: editIxKdK4ebTgMeta?.alias || [],
    redirect: editIxKdK4ebTgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/order/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93eMOkg5ocmfMeta || {},
    alias: _91id_93eMOkg5ocmfMeta?.alias || [],
    redirect: _91id_93eMOkg5ocmfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/order/[id].vue").then(m => m.default || m)
  },
  {
    name: addFJop38bdKIMeta?.name ?? "sale-order-add",
    path: addFJop38bdKIMeta?.path ?? "/sale/order/add",
    meta: addFJop38bdKIMeta || {},
    alias: addFJop38bdKIMeta?.alias || [],
    redirect: addFJop38bdKIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/order/add.vue").then(m => m.default || m)
  },
  {
    name: importVJDWfUPeMNMeta?.name ?? "sale-order-import",
    path: importVJDWfUPeMNMeta?.path ?? "/sale/order/import",
    meta: importVJDWfUPeMNMeta || {},
    alias: importVJDWfUPeMNMeta?.alias || [],
    redirect: importVJDWfUPeMNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/order/import.vue").then(m => m.default || m)
  },
  {
    name: indexirPoMIJU22Meta?.name ?? "sale-order",
    path: indexirPoMIJU22Meta?.path ?? "/sale/order",
    meta: indexirPoMIJU22Meta || {},
    alias: indexirPoMIJU22Meta?.alias || [],
    redirect: indexirPoMIJU22Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/order/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93GbUy4AtJQGMeta?.name ?? "sale-recurring-invoice-id",
    path: _91id_93GbUy4AtJQGMeta?.path ?? "/sale/recurring-invoice/:id()",
    children: [
  {
    name: editGG1WAUtsRpMeta?.name ?? "sale-recurring-invoice-id-edit",
    path: editGG1WAUtsRpMeta?.path ?? "edit",
    meta: editGG1WAUtsRpMeta || {},
    alias: editGG1WAUtsRpMeta?.alias || [],
    redirect: editGG1WAUtsRpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/recurring-invoice/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93GbUy4AtJQGMeta || {},
    alias: _91id_93GbUy4AtJQGMeta?.alias || [],
    redirect: _91id_93GbUy4AtJQGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/recurring-invoice/[id].vue").then(m => m.default || m)
  },
  {
    name: addLe5Fuawc3LMeta?.name ?? "sale-recurring-invoice-add",
    path: addLe5Fuawc3LMeta?.path ?? "/sale/recurring-invoice/add",
    meta: addLe5Fuawc3LMeta || {},
    alias: addLe5Fuawc3LMeta?.alias || [],
    redirect: addLe5Fuawc3LMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/recurring-invoice/add.vue").then(m => m.default || m)
  },
  {
    name: importKVTbM4C9GEMeta?.name ?? "sale-recurring-invoice-import",
    path: importKVTbM4C9GEMeta?.path ?? "/sale/recurring-invoice/import",
    meta: importKVTbM4C9GEMeta || {},
    alias: importKVTbM4C9GEMeta?.alias || [],
    redirect: importKVTbM4C9GEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/recurring-invoice/import.vue").then(m => m.default || m)
  },
  {
    name: indexMf9NsWzi48Meta?.name ?? "sale-recurring-invoice",
    path: indexMf9NsWzi48Meta?.path ?? "/sale/recurring-invoice",
    meta: indexMf9NsWzi48Meta || {},
    alias: indexMf9NsWzi48Meta?.alias || [],
    redirect: indexMf9NsWzi48Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/sale/recurring-invoice/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yZ4BXyG8WyMeta?.name ?? "tax-tax-authority-id",
    path: _91id_93yZ4BXyG8WyMeta?.path ?? "/tax/tax-authority/:id()",
    children: [
  {
    name: editot5v9mYWloMeta?.name ?? "tax-tax-authority-id-edit",
    path: editot5v9mYWloMeta?.path ?? "edit",
    meta: editot5v9mYWloMeta || {},
    alias: editot5v9mYWloMeta?.alias || [],
    redirect: editot5v9mYWloMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax-authority/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93yZ4BXyG8WyMeta || {},
    alias: _91id_93yZ4BXyG8WyMeta?.alias || [],
    redirect: _91id_93yZ4BXyG8WyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax-authority/[id].vue").then(m => m.default || m)
  },
  {
    name: add8WqLM6TKXcMeta?.name ?? "tax-tax-authority-add",
    path: add8WqLM6TKXcMeta?.path ?? "/tax/tax-authority/add",
    meta: add8WqLM6TKXcMeta || {},
    alias: add8WqLM6TKXcMeta?.alias || [],
    redirect: add8WqLM6TKXcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax-authority/add.vue").then(m => m.default || m)
  },
  {
    name: importJktsOjPaisMeta?.name ?? "tax-tax-authority-import",
    path: importJktsOjPaisMeta?.path ?? "/tax/tax-authority/import",
    meta: importJktsOjPaisMeta || {},
    alias: importJktsOjPaisMeta?.alias || [],
    redirect: importJktsOjPaisMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax-authority/import.vue").then(m => m.default || m)
  },
  {
    name: indexojqYb8TOExMeta?.name ?? "tax-tax-authority",
    path: indexojqYb8TOExMeta?.path ?? "/tax/tax-authority",
    meta: indexojqYb8TOExMeta || {},
    alias: indexojqYb8TOExMeta?.alias || [],
    redirect: indexojqYb8TOExMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax-authority/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93GLfDMkiLUJMeta?.name ?? "tax-tax-exemption-id",
    path: _91id_93GLfDMkiLUJMeta?.path ?? "/tax/tax-exemption/:id()",
    children: [
  {
    name: editBLboUieLhkMeta?.name ?? "tax-tax-exemption-id-edit",
    path: editBLboUieLhkMeta?.path ?? "edit",
    meta: editBLboUieLhkMeta || {},
    alias: editBLboUieLhkMeta?.alias || [],
    redirect: editBLboUieLhkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax-exemption/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93GLfDMkiLUJMeta || {},
    alias: _91id_93GLfDMkiLUJMeta?.alias || [],
    redirect: _91id_93GLfDMkiLUJMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax-exemption/[id].vue").then(m => m.default || m)
  },
  {
    name: addVSzEa13ALiMeta?.name ?? "tax-tax-exemption-add",
    path: addVSzEa13ALiMeta?.path ?? "/tax/tax-exemption/add",
    meta: addVSzEa13ALiMeta || {},
    alias: addVSzEa13ALiMeta?.alias || [],
    redirect: addVSzEa13ALiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax-exemption/add.vue").then(m => m.default || m)
  },
  {
    name: importuVbO3Af1mfMeta?.name ?? "tax-tax-exemption-import",
    path: importuVbO3Af1mfMeta?.path ?? "/tax/tax-exemption/import",
    meta: importuVbO3Af1mfMeta || {},
    alias: importuVbO3Af1mfMeta?.alias || [],
    redirect: importuVbO3Af1mfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax-exemption/import.vue").then(m => m.default || m)
  },
  {
    name: indexUsGPuv6ZEVMeta?.name ?? "tax-tax-exemption",
    path: indexUsGPuv6ZEVMeta?.path ?? "/tax/tax-exemption",
    meta: indexUsGPuv6ZEVMeta || {},
    alias: indexUsGPuv6ZEVMeta?.alias || [],
    redirect: indexUsGPuv6ZEVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax-exemption/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_9379in7Ad1k1Meta?.name ?? "tax-tax-rate-id",
    path: _91id_9379in7Ad1k1Meta?.path ?? "/tax/tax-rate/:id()",
    children: [
  {
    name: editbHqft4cVEEMeta?.name ?? "tax-tax-rate-id-edit",
    path: editbHqft4cVEEMeta?.path ?? "edit",
    meta: editbHqft4cVEEMeta || {},
    alias: editbHqft4cVEEMeta?.alias || [],
    redirect: editbHqft4cVEEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax-rate/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_9379in7Ad1k1Meta || {},
    alias: _91id_9379in7Ad1k1Meta?.alias || [],
    redirect: _91id_9379in7Ad1k1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax-rate/[id].vue").then(m => m.default || m)
  },
  {
    name: addSZVi7QFKJuMeta?.name ?? "tax-tax-rate-add",
    path: addSZVi7QFKJuMeta?.path ?? "/tax/tax-rate/add",
    meta: addSZVi7QFKJuMeta || {},
    alias: addSZVi7QFKJuMeta?.alias || [],
    redirect: addSZVi7QFKJuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax-rate/add.vue").then(m => m.default || m)
  },
  {
    name: importwRQUyjVarnMeta?.name ?? "tax-tax-rate-import",
    path: importwRQUyjVarnMeta?.path ?? "/tax/tax-rate/import",
    meta: importwRQUyjVarnMeta || {},
    alias: importwRQUyjVarnMeta?.alias || [],
    redirect: importwRQUyjVarnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax-rate/import.vue").then(m => m.default || m)
  },
  {
    name: indexlsenR1TytcMeta?.name ?? "tax-tax-rate",
    path: indexlsenR1TytcMeta?.path ?? "/tax/tax-rate",
    meta: indexlsenR1TytcMeta || {},
    alias: indexlsenR1TytcMeta?.alias || [],
    redirect: indexlsenR1TytcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax-rate/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ovLHKhEk9rMeta?.name ?? "tax-tax-id",
    path: _91id_93ovLHKhEk9rMeta?.path ?? "/tax/tax/:id()",
    children: [
  {
    name: editWo9HPFArDlMeta?.name ?? "tax-tax-id-edit",
    path: editWo9HPFArDlMeta?.path ?? "edit",
    meta: editWo9HPFArDlMeta || {},
    alias: editWo9HPFArDlMeta?.alias || [],
    redirect: editWo9HPFArDlMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax/[id]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91id_93ovLHKhEk9rMeta || {},
    alias: _91id_93ovLHKhEk9rMeta?.alias || [],
    redirect: _91id_93ovLHKhEk9rMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax/[id].vue").then(m => m.default || m)
  },
  {
    name: addI8TsDyranCMeta?.name ?? "tax-tax-add",
    path: addI8TsDyranCMeta?.path ?? "/tax/tax/add",
    meta: addI8TsDyranCMeta || {},
    alias: addI8TsDyranCMeta?.alias || [],
    redirect: addI8TsDyranCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax/add.vue").then(m => m.default || m)
  },
  {
    name: importvmbSVVNhIUMeta?.name ?? "tax-tax-import",
    path: importvmbSVVNhIUMeta?.path ?? "/tax/tax/import",
    meta: importvmbSVVNhIUMeta || {},
    alias: importvmbSVVNhIUMeta?.alias || [],
    redirect: importvmbSVVNhIUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax/import.vue").then(m => m.default || m)
  },
  {
    name: indexMidc7FMhgbMeta?.name ?? "tax-tax",
    path: indexMidc7FMhgbMeta?.path ?? "/tax/tax",
    meta: indexMidc7FMhgbMeta || {},
    alias: indexMidc7FMhgbMeta?.alias || [],
    redirect: indexMidc7FMhgbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/tax/tax/index.vue").then(m => m.default || m)
  },
  {
    name: user_45managementkNAqgHt6GAMeta?.name ?? "user-management",
    path: user_45managementkNAqgHt6GAMeta?.path ?? "/user-management",
    meta: user_45managementkNAqgHt6GAMeta || {},
    alias: user_45managementkNAqgHt6GAMeta?.alias || [],
    redirect: user_45managementkNAqgHt6GAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/.app/pages/user-management.vue").then(m => m.default || m)
  }
]