import revive_payload_client_VtYSr9ux87 from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.3.0_@types+node@20.14.2_@unocss+reset@0_56xwn7dx4nn3jslq7z2hsfr3x4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2xZ0smLSBm from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.3.0_@types+node@20.14.2_@unocss+reset@0_56xwn7dx4nn3jslq7z2hsfr3x4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ueEh3BgVLB from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.3.0_@types+node@20.14.2_@unocss+reset@0_56xwn7dx4nn3jslq7z2hsfr3x4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_BLgRS2c5Ka from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.3.0_@types+node@20.14.2_@unocss+reset@0_56xwn7dx4nn3jslq7z2hsfr3x4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_pAj3QkqAWG from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.3.0_@types+node@20.14.2_@unocss+reset@0_56xwn7dx4nn3jslq7z2hsfr3x4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_kUX1d63mPH from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.4.5_vue@3.4.27_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.app/.nuxt/components.plugin.mjs";
import prefetch_client_sdbMo7AZPr from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.3.0_@types+node@20.14.2_@unocss+reset@0_56xwn7dx4nn3jslq7z2hsfr3x4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_QoIYtoyOER from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_rollup@4.18.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_iErJdkOGud from "/vercel/path0/node_modules/.pnpm/@cssninja+nuxt-toaster@0.3.12_rollup@4.18.0_vue@3.4.29_typescript@5.4.5_/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import plugin_FZmSedP62C from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.22.7_rollup@4.18.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import chunk_reload_client_hX50OgdV23 from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.3.0_@types+node@20.14.2_@unocss+reset@0_56xwn7dx4nn3jslq7z2hsfr3x4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import directives_yBPzJQtdia from "/vercel/path0/layers/tairo/plugins/directives.ts";
import analytics_client_mtqVw3YRXk from "/vercel/path0/.app/plugins/analytics.client.ts";
import google_recaptcha_rYK9Kziv68 from "/vercel/path0/.app/plugins/google-recaptcha.ts";
import tawk_messenger_client_T4BIlRqwb5 from "/vercel/path0/.app/plugins/tawk-messenger.client.ts";
import vue_axe_RbYD3MO140 from "/vercel/path0/.app/plugins/vue-axe.ts";
export default [
  revive_payload_client_VtYSr9ux87,
  unhead_2xZ0smLSBm,
  router_ueEh3BgVLB,
  payload_client_BLgRS2c5Ka,
  check_outdated_build_client_pAj3QkqAWG,
  plugin_vue3_kUX1d63mPH,
  components_plugin_KR1HBZs4kY,
  prefetch_client_sdbMo7AZPr,
  plugin_client_QoIYtoyOER,
  plugin_iErJdkOGud,
  plugin_FZmSedP62C,
  chunk_reload_client_hX50OgdV23,
  directives_yBPzJQtdia,
  analytics_client_mtqVw3YRXk,
  google_recaptcha_rYK9Kziv68,
  tawk_messenger_client_T4BIlRqwb5,
  vue_axe_RbYD3MO140
]